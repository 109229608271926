<template>
  <div class="grid grid-cols-2 gap-2">
    <Accordion :groups="tabs" :active="activeTab" @click="handleTab" />

    <div>
      <Dropdown
        class="mb-3"
        label="Condition Type"
        text-attribute="text"
        value-attribute="value"
        :value="queryType"
        :options="conditionTypes"
        @input="queryType = $event"
      />

      <Code
        lang="sql"
        :value="query"
        @input="query = $event"
        @blur="buildConditions"
      />
      <Button
        status="primary"
        :loading="appling"
        :disabled="appling"
        class="justify-center mt-3"
        @click="handleApply"
      >
        <span>Apply</span>
      </Button>
    </div>
  </div>
</template>

<script>
import { defineComponent, inject, ref, toRefs, watch } from "vue";
// import { PlusIcon, TrashIcon } from "@heroicons/vue/outline";
import uuidV4 from "uuid/v4";
import last from "lodash/last";
import first from "lodash/first";
import Button from "@/components/common/Button";
import Accordion from "@/components/common/accordion/Index";
import Code from "@/components/common/fields/Code";
// import Highlight from "@/components/common/fields/Highlight";
import Dropdown from "@/components/common/fields/Dropdown";

const combines = ["", "AND", "OR"];
const operators = ["=", "!=", "like"];
const conditionTypes = [
  { text: "Query", value: "query" },
  { text: "True Condition", value: "true_condition" },
];

const group = {
  combine: "",
  type: "group",
  direction: "start",
};

const condition = {
  value: "",
  field: "",
  combine: "",
  operator: "",
  type: "condition",
};

export default defineComponent({
  emits: ["update"],

  components: {
    Accordion,
    Button,
    Code,
    // Highlight,
    Dropdown,
    // PlusIcon,
    // TrashIcon,
  },

  props: {
    link: Object,
    workflow: Object,
    functions: Array,
    sourceFields: Array,
    destinationFields: Array,
  },

  setup(props, context) {
    const conditions = useConditions(props);
    const tab = useTabs(props, conditions);
    const apply = useApply(props, context, conditions);

    return {
      combines,
      operators,
      conditionTypes,
      ...tab,
      ...apply,
      ...conditions,
    };
  },
});

function useApply(props, { emit }, { query, queryType }) {
  const $axios = inject("axios");
  const appling = ref(false);

  async function handleApply() {
    appling.value = true;

    try {
      const values = {
        condition: {
          type: queryType.value,
          value: btoa(query.value),
        },
      };

      await $axios.put(
        `/workflows/${props.workflow._id}/links/${props.link._id}`,
        values
      );

      emit("update", values);
    } catch (error) {
      //
    }

    appling.value = false;
  }

  return {
    appling,
    handleApply,
  };
}

function useTabs(props, { concatValue }) {
  const tabs = [
    {
      title: "Destination Fields",
      items: props.destinationFields.map((item) => ({
        text: item.name + ` [:[applied->${item.name}]:]`,
        signature: `[:[applied->${item.name}]:]`,
      })),
    },
    {
      title: "Applied Fields",
      items: props.destinationFields.map((item) => ({
        text: item.name + ` [:[applied->${item.name}]:]`,
        signature: `[:[applied->${item.name}]:]`,
      })),
    },
    {
      title: "Source Fields",
      items: props.sourceFields.map((item) => ({
        text: item.name + ` [:[raw->${item.name}]:]`,
        signature: `[:[raw->${item.name}]:]`,
      })),
    },
    {
      title: "Functions",
      items: props.functions.map((item) => ({
        text: item.name + ` {:{${item.signature}}:}`,
        signature: `{:{${item.signature}}:}`,
      })),
    },
  ];

  const handleTab = (field) => {
    // concatValue(field.signature);
  };

  return { tabs, activeTab: "Destination Fields", handleTab };
}

function useConditions(props) {
  const { link } = toRefs(props);

  const conditions = ref([]);
  const selectionStart = ref(0);
  const groupOpened = ref(false);
  const bluredCondition = ref(null);
  const bluredConditionField = ref(null);
  const query = ref(link.value?.condition?.value ?? "");
  const queryType = ref(link.value?.condition?.type ?? "query");

  // watch(conditions, () => {
  //   query.value = stringifySQL(conditions.value);
  // });

  function concatValue(signature) {
    let field = bluredConditionField.value;
    let conditionId = bluredCondition.value;

    if (!conditionId) {
      conditionId = first(conditions).id;
    }

    if (!field) {
      field = "value";
    }

    conditions.value = conditions.value.map((i) => {
      if (i.id === conditionId) {
        i[field] = (i[field] ?? "").splice(selectionStart.value, 0, signature);
      }

      return i;
    });
  }

  function createCondition() {
    conditions.value.push({ ...condition, id: uuidV4() });
  }

  function removeCondition(id) {
    conditions.value = conditions.value.filter((item) => item.id !== id);
  }

  function setFieldValue(id, field, value) {
    conditions.value = conditions.value.map((condition) => {
      return condition.id === id ? { ...condition, [field]: value } : condition;
    });
  }

  function setFieldBlur(id, field) {
    bluredCondition.value = id;
    bluredConditionField.value = field;
    selectionStart.value = document.getSelection().focusOffset;
  }

  function switchGroup() {
    let condition = {
      ...group,
      id: uuidV4(),
    };

    if (groupOpened.value) {
      condition.direction = "end";
    } else {
      condition.direction = "start";
    }

    conditions.value = [...conditions.value, condition];
    groupOpened.value = !groupOpened.value;
  }

  function buildConditions() {
    conditions.value = parseSQL(query.value);
    const group = last(conditions.value.filter((i) => i.type === "group"));
    if (group && group.direction === "start") {
      groupOpened.value = true;
    }
  }

  if (query.value) {
    try {
      query.value = atob(query.value);
    } catch (err) {
      //
    }
    buildConditions();
  }

  return {
    query,
    queryType,
    conditions,
    conditionTypes,
    concatValue,
    groupOpened,
    switchGroup,
    setFieldBlur,
    setFieldValue,
    createCondition,
    removeCondition,
    buildConditions,
  };
}

const operatorsJoins = operators.join("|");
const combinesJoins = combines.filter(Boolean).join("|");

const combinesMatches = new RegExp(`(${combinesJoins})`, "gi");
const operatorsMatches = new RegExp(`(${operatorsJoins})`, "gi");

const combinesToken = new RegExp(`(.*?${combinesJoins})`, "gi");
const combinesStartsWith = new RegExp(`^(${combinesJoins})`, "gi");

const createConditions = (string) => {
  return string
    .split(combinesToken)
    .map((i) => i.trim())
    .filter(Boolean)
    .map((value) => {
      const combain = first(value.match(combinesMatches));
      const operator = first(value.match(operatorsMatches));

      if (combain) {
        value = value.replace(combain, "").trim();
      }

      if (operator) {
        value = value.replace(operator, "").trim();
      }

      const [field, fieldValue] = value
        .split(" ")
        .map((i) => i.trim())
        .filter(Boolean);

      return {
        ...condition,
        id: uuidV4(),
        field: field,
        operator: operator,
        combine: combain?.toUpperCase(),
        value: fieldValue?.replaceAll('"', ""),
      };
    });
};

function parseSQL(string) {
  return string
    .split(/([()])/gi)
    .map((i) => i.trim())
    .reduce((carry, item) => {
      if (item === "(") {
        carry.push({ ...group, id: uuidV4() });
      } else if (item === ")") {
        carry.push({ ...group, id: uuidV4(), direction: "end" });
      } else {
        const groupCombain = first(item.match(combinesStartsWith));
        if (groupCombain) {
          item = item.replace(groupCombain, "").trim();

          const group = last(carry);
          if (group?.type === "group" && group?.direction === "end") {
            carry = carry.map((i, idx) => {
              return idx === carry.length - 1
                ? { ...i, combine: groupCombain.toUpperCase() }
                : i;
            });
          }
        }

        carry = carry.concat(createConditions(item));
      }

      return carry;
    }, []);
}

function stringifySQL(items) {
  return items.reduce((carry, item) => {
    let value = "";

    if (item.type === "group") {
      value = item.direction === "start" ? "(" : ")";
    } else {
      value = item.value ? `"${item.value}"` : "";
    }

    return [carry, item.field, item.operator, value, item.combine]
      .filter(Boolean)
      .join(" ")
      .trim();
  }, "");
}
</script>
