// @ts-ignore
import icons from "@/assets/icons.svg";

export const NODE_WIDTH = 150;
export const NODE_HEIGHT = 80;

export const GROUP_WIDTH = 250;
export const GROUP_HEIGHT = 150;

export enum NodeStatus {
  Draft = "Draft",
  Active = "Active",
}

export enum NodeType {
  Group = "Group",
  Fetch = "Fetch",
  Listen = "Listen",
  Fulfill = "Fulfill",
}

export enum LinkPosition {
  left = "left",
  Right = "right",
}

export function nodeIcon(node: any) {
  return `${icons}#${node.connection?.adapter?.icon}`;
}

export function nodeSize(node: any) {
  let width = node.width;
  let height = node.height;

  if (!width) {
    width = node.type === NodeType.Group ? GROUP_WIDTH : NODE_WIDTH;
  }

  if (!height) {
    height = node.type === NodeType.Group ? GROUP_HEIGHT : NODE_HEIGHT;
  }

  return { width, height };
}

export function into(group: any, node: any) {
  const nx1 = node.x;
  const ny1 = node.y;

  const nw = node.width;
  const nh = node.height;

  const nx2 = nx1 + nw;
  const ny2 = ny1 + nh;

  const gx1 = group.x;
  const gx2 = gx1 + group.width;

  const gy1 = group.y;
  const gy2 = gy1 + group.height;

  return gx1 < nx1 && nx2 < gx2 && gy1 < ny1 && ny2 < gy2;
}

export function linkCoordinate({
  source,
  destination,
  source_properties,
  destination_properties,
}: any): any {
  return {
    source: linkSourceCoordinate(source, source_properties.position),
    target: linkDestinationCoordinate(
      destination,
      destination_properties.position
    ),
  };
}

export function linkSourceCoordinate(
  node: any,
  position = LinkPosition.Right
): any {
  const size = nodeSize(node);

  let x = node.x;
  if (position === LinkPosition.Right) {
    x += size.width;
  }

  return [x, node.y + size.height / 2 + 3];
}

export function linkDestinationCoordinate(
  node: any,
  position = LinkPosition.left
): any {
  const size = nodeSize(node);
  return [node.x - 5, node.y + size.height / 2 + 3];
}
