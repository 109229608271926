<template>
  <li>
    <a
      :href="item.href"
      class="block hover:bg-primary-lighter"
      @click.prevent="$emit('select')"
      :class="{ 'bg-primary-lighter': selected }"
    >
      <div class="flex items-center px-4 py-4 sm:px-6">
        <div class="min-w-0 flex-1 flex items-center">
          <div class="flex-shrink-0">
            <LightningBoltIcon
              class="h-10 w-10 text-primary"
              aria-hidden="true"
            />
          </div>
          <div class="min-w-0 flex-1 px-4 md:grid md:gap-4">
            <div>
              <p class="text-sm font-medium border-primary truncate">
                {{ item.name }}
              </p>
              <p class="flex items-center text-sm text-secondary-dark">
                {{ item.key }}
              </p>
            </div>
          </div>
        </div>
        <div>
          <Spinner v-if="loading" class="h-5 w-5 text-gray-400" />

          <ChevronRightIcon
            v-else
            class="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </div>
      </div>
    </a>
  </li>
</template>

<script>
import { defineComponent } from "vue";
import { ChevronRightIcon } from "@heroicons/vue/solid";
import { LightningBoltIcon } from "@heroicons/vue/solid";
import Spinner from "@/components/common/Spinner";

export default defineComponent({
  emits: ["select"],

  components: {
    Spinner,
    ChevronRightIcon,
    LightningBoltIcon,
  },

  props: {
    item: Object,
    loading: Boolean,
    selected: Boolean,
  },
});
</script>
