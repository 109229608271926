<script>
import set from "lodash/set";
import get from "lodash/get";
import omit from "lodash/omit";
import cloneDeep from "lodash-es/cloneDeep";
import { h, defineComponent } from "vue";
import { flatten } from "flatulence";

function eventOrValue(e) {
  if (e instanceof Event) {
    if (e.target.options) {
      const selectedOption = e.target.options[e.target.selectedIndex];

      return selectedOption._value !== undefined
        ? selectedOption._value
        : selectedOption.value;
    }

    if (e.target.type === "checkbox") {
      return e.target.checked;
    }

    return e.target.value;
  }

  if (e?.value) {
    return e.value;
  }

  return e;
}

export default defineComponent({
  emits: ["submit", "values"],
  props: ["submitOnBlur", "value", "schema"],
  data() {
    return {
      values: JSON.parse(JSON.stringify(this.value)),
      touched: {},
      submitted: false,
      submitting: false,
      scrolledToFirstError: { value: false },
    };
  },
  watch: {
    values: {
      deep: true,
      immediate: true,
      handler() {
        this.$emit("values", { values: this.values, setValue: this.setValues });
      },
    },
  },
  provide() {
    return {
      form: {
        values: this.values,
        setValue: (field, value) => this.setValues({ [field]: value }),
        errors: this.getErrors,
        touched: this.getTouched,
        setTouched: this.setTouched,
        input: this.handleInput,
        blur: this.handleBlur,
        scrolledToFirstError: this.scrolledToFirstError,
      },
    };
  },
  computed: {
    errors() {
      return this.validate();
    },
  },
  methods: {
    getErrors(field) {
      const error = get(this.errors, field);

      if (error) {
        return error;
      }

      if (!this.getTouched(field)) {
        return null;
      }

      return get(this.errors, field);
    },
    getTouched(field) {
      return get(this.touched, field);
    },
    setValues(values) {
      Object.entries(values).forEach(([key, val]) => {
        set(this.values, key, val);
      });
    },
    handleInput(e, name) {
      this.setValues({
        [name ? name : e.target ? e.target.name : e.name]: eventOrValue(e),
      });
    },
    handleBlur(e, name) {
      this.setTouched(name ? name : e.target ? e.target.name : e);
    },
    handleFocus(e, name) {
      this.untouch(name ? name : e.target ? e.target.name : e);
    },
    setTouched(field) {
      set(this.touched, field, true);

      if (this.submitOnBlur) {
        this.handleSubmit();
      }
    },
    untouch(field) {
      this.touched = omit(this.touched, field);
    },
    handleSubmit() {
      this.scrolledToFirstError.value = false;

      if (this.errors && Object.keys(this.errors).length > 0) {
        return;
      }

      this.$emit("submit", {
        values: this.values,
        setSubmitting: () => {
          this.submitting = true;
          this.submitted = false;
        },
        setSubmitted: () => {
          this.submitting = false;
          this.submitted = true;
        },
      });
    },
    validate() {
      try {
        this.schema.validateSync(this.values, { abortEarly: false });
      } catch (error) {
        const errors = error.inner.reduce((reducer, item) => {
          reducer = cloneDeep(reducer);
          const path = item.path.replaceAll("[", ".").replaceAll("]", "");
          set(reducer, path, item.message);
          return reducer;
        }, {});
        return errors;
      }
    },
    submit() {
      this.touched = [
        ...Object.entries(flatten.keepEmpty(this.values)),
        ...Object.entries(this.values),
      ].reduce((touched, [key]) => ({ ...touched, [key]: true }), {});

      this.handleSubmit();
    },
  },
  render() {
    const $vnodes = this.$slots.default({
      input: this.handleInput,
      blur: this.handleBlur,
      focus: this.handleFocus,
      setValue: (field, value) => this.setValues({ [field]: value }),
      setTouched: this.setTouched,
      untouch: this.untouch,
      values: this.values,
      errors: this.getErrors,
      touched: this.getTouched,
      submitted: this.submitted,
      submitting: this.submitting,
      submit: this.submit,
    });

    if ($vnodes.length === 0) {
      return $vnodes[0];
    }

    return h("div", $vnodes);
  },
});
</script>
