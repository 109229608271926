<template>
  <div>
    <div v-if="loading">loading...</div>
    <template v-else>
      <component
        :is="state"
        :link="link"
        :workflow="workflow"
        :source-node="provider"
        :source-fields="sourceFields"
        :destination-node="destination"
        :destination-fields="destinationFields"
        @update="$emit('update', $event)"
      >
        <Button
          class="ml-1"
          v-tooltip="'Conditions'"
          @click="handleStateChange"
        >
          <XIcon v-if="state === 'Conditions'" class="w-5 h-5" />
          <FilterIcon v-else class="w-5 h-5" />
        </Button>
      </component>
    </template>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { FilterIcon, XIcon } from "@heroicons/vue/outline";
import { clean } from "@/lib/helpers";
import Button from "@/components/common/Button";
import Graph from "@/components/workflows/transform/graph/Index";
import Conditions from "@/components/workflows/transform/conditions/Index";

export default defineComponent({
  emits: ["update"],

  components: {
    Button,
    Conditions,
    Graph,
    FilterIcon,
    XIcon,
  },

  props: {
    link: Object,
    source: Object,
    workflow: Object,
    destination: Object,
  },

  data() {
    return {
      loading: true,
      state: "Graph",
      provider: null,
      sourceFields: [],
      destinationFields: [],
    };
  },

  methods: {
    async load() {
      this.loading = true;

      try {
        const { data: provider } = await this.$axios.get(
          `/workflows/${this.workflow._id}/nodes/${this.link.provider_id}`
        );

        const [{ data: sourceFields }, { data: destinationFields }] =
          await Promise.all([
            this.$axios.post("/brokers/datasets", {
              connection_id: provider.data.connection_id,
              values: clean(provider.data.connection_values),
            }),
            this.$axios.post("/brokers/datasets", {
              connection_id: this.destination.connection_id,
              values: clean(this.destination.connection_values),
            }),
          ]);

        this.provider = provider.data;
        this.sourceFields = sourceFields.data.find(
          (i) => i.name === this.link.dataset
        ).fields;

        this.destinationFields = destinationFields.data[0].fields;
      } catch (error) {
        console.log("error", error);
        //
      }

      this.loading = false;
    },
    handleStateChange() {
      if (this.state === "Conditions") {
        this.state = "Graph";
      } else {
        this.state = "Conditions";
      }
    },
  },

  created() {
    this.load();
  },
});
</script>
