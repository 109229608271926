
import { defineComponent } from "vue";
import { computed, toRefs } from "vue";
import { ChipIcon } from "@heroicons/vue/outline";
import { line2 } from "@/lib/svg";
import { linkCoordinate } from "./flow";

export default defineComponent({
  components: {
    ChipIcon,
  },

  props: {
    link: Object,
  },

  setup(props) {
    const { link } = toRefs(props);

    const linker = computed(() => {
      const { source, target } = linkCoordinate(link.value);

      const sourceX = source[0];
      const sourceY = source[1];

      const destinationX = target[0];
      const destinationY = target[1];

      const sourcePosition = link.value?.source_properties?.position;
      const destinationPosition = link.value?.destination_properties?.position;

      const { lines } = line2(
        sourceX,
        sourceY,
        destinationX,
        destinationY,
        sourcePosition,
        destinationPosition
      );

      return lines.find((i: any) => i.finish);
    });

    const linkStyle = computed(() => {
      if (!linker.value) {
        return {};
      }

      return {
        top: `${linker.value.sourceY - 28}px`,
        left: `${linker.value.sourceX - 23}px`,
        width: `${50}px`,
        height: `${50}px`,
      };
    });

    return {
      linkStyle,
    };
  },
});
