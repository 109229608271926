<template>
  <div :id="nodeId" :style="nodeStyle" class="absolute flex z-20 group">
    <div class="flex-1 relative flex flex-col">
      <Link v-if="!disabled" position="left" @link="$emit('link', $event)" />
      <Link position="right" @link="$emit('link', $event)" />
      <Actions :node="node" class="hidden group-hover:flex" ref="actionRefs" />

      <component :node="node" :is="node.type" @configure="handleConfigure" />
    </div>
  </div>
</template>

<script>
import { defineComponent, toRefs, ref } from "vue";
import { useNode } from "./use/node";
import Link from "./actions/Link";
import Actions from "./actions/NodeActions";
import Fetch from "./nodes/Fetch";
import Listen from "./nodes/Listen";
import Fulfill from "./nodes/Fulfill";

export default defineComponent({
  emits: ["link"],

  components: {
    Link,
    Actions,
    Listen,
    Fetch,
    Fulfill,
  },

  props: {
    node: Object,
    active: Boolean,
    boundary: Object,
  },

  setup(props) {
    const actionRefs = ref(null);
    const { node, boundary } = toRefs(props);

    const handleConfigure = () => {
      actionRefs.value.$refs.configure.prompt = true;
    };

    return {
      ...useNode(node, boundary),
      actionRefs,
      handleConfigure,
    };
  },
});
</script>
