import { defineComponent } from "vue";
import Code from "@/components/common/fields/Code.vue";
import Tab from "@/components/common/fields/Tab.vue";
import Number from "@/components/common/fields/Number.vue";
import Radio from "@/components/common/fields/Radio.vue";
import File from "@/components/common/fields/File.vue";
import Highlight from "@/components/common/fields/Highlight.vue";
import Checkbox from "@/components/common/fields/Checkbox.vue";
import Textbox from "@/components/common/fields/Textbox.vue";
import Dropdown from "@/components/common/fields/Dropdown.vue";
import Password from "@/components/common/fields/Password.vue";
import Textarea from "@/components/common/fields/Textarea.vue";
import ServiceToken from "@/components/common/fields/ServiceToken.vue";

export default defineComponent({
  components: {
    Code,
    Dropdown,
    Textbox,
    Tab,
    File,
    Radio,
    Checkbox,
    Number,
    Password,
    Textarea,
    Highlight,
    ServiceToken,
  },
});
