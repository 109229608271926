<template>
  <div>
    <Textbox @input="load" placeholder="Search connection adapters." />

    <div v-if="adaptersLoading" class="flex justify-center py-6">
      <Spinner class="w-10 h-10 text-gray-400" />
    </div>

    <ul class="divide-y divide-gray-200 mt-3" v-else>
      <ListItem
        v-for="adapter in adapters"
        :item="adapter"
        :key="adapter._id"
        :selected="adapter._id === selectedId"
        :loading="adapter._id === selectedId && adapterLoading"
        @select="handleSelect(adapter)"
      />
    </ul>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
import { defineComponent } from "vue";
import Textbox from "@/components/common/fields/Textbox";
import Spinner from "@/components/common/Spinner";
import ListItem from "./ListItem";

export default defineComponent({
  emits: ["adapter"],

  components: {
    Textbox,
    Spinner,
    ListItem,
  },

  data() {
    return {
      adapters: [],
      selectedId: null,
      adapterLoading: false,
      adaptersLoading: true,
    };
  },

  methods: {
    load: debounce(async function (event) {
      this.adaptersLoading = true;

      try {
        const { data: adapters } = await this.$axios.get("/adapters", {
          params: {
            "filters[name]": event?.target.value ?? "",
          },
        });

        this.adapters = adapters.data;
      } catch (error) {
        console.log(error);
      }

      this.adaptersLoading = false;
    }, 300),

    async handleSelect(item) {
      this.selectedId = item._id;
      this.adapterLoading = true;

      try {
        const { data: adapter } = await this.$axios.get(
          `/adapters/${this.selectedId}`
        );

        this.$emit("adapter", adapter.data);
      } catch (error) {
        this.selectedId = null;
      }

      this.adapterLoading = false;
    },
  },

  created() {
    this.load();
  },
});
</script>
