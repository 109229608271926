<template>
  <Modal
    open
    action="Apply"
    title="Apply Functions"
    @close="handleClose"
    @submit="handleApply"
  >
    <Form
      ref="form"
      :schema="schema"
      :value="formValues"
      @submit="handleSubmit"
    >
      <template #default="{ submit, values, input, blur, focus, setValue }">
        <form @submit.prevent="submit">
          <Tab
            name="mode"
            :tabs="functionModes"
            :value="values.mode"
            v-on="{ input, blur }"
            @change="setValue('code', '')"
          />

          <template v-if="values.mode === 0">
            <div class="flex justify-between mt-3">
              <Dropdown
                class="flex-1"
                label="Function"
                text-attribute="name"
                value-attribute="_id"
                :options="functions"
                :value="activeFunctionId"
                @input="activeFunctionId = $event"
              />
              <Button
                class="self-end ml-1"
                @click="handleFunctionAdd(values, setValue)"
              >
                Add
              </Button>
            </div>
            <div class="flex justify-between mt-3" v-if="fields.length">
              <Dropdown
                label="Field"
                class="flex-1"
                :options="fields"
                :value="activeField"
                text-attribute="name"
                value-attribute="name"
                @input="activeField = $event"
              />
              <Button
                class="self-end ml-1"
                @click="handleFieldAdd(values, setValue)"
              >
                Add
              </Button>
            </div>

            <div v-if="activeFunctionId" class="mt-1">
              <p class="text-sm">{{ activeFunction.description }}</p>
              <div class="bg-primary-lighter p-1 rounded mt-1">
                <div
                  :key="sample"
                  v-for="sample in activeFunction.samples"
                  class="italic text-blue-800 text-sm"
                >
                  {{ sample }}
                </div>
              </div>
            </div>
            <Highlight
              ref="code"
              :rows="10"
              class="mt-3"
              name="value"
              label="Value"
              :value="values.value"
              v-on="{ input, focus }"
              @blur="handleCodeBlur"
            />
          </template>
          <template v-if="values.mode === 1">
            <Dropdown
              class="mt-3"
              label="Language"
              :options="languages"
              :value="values.lang"
              @input="values.lang = $event"
            />
            <Code
              ref="code"
              class="mt-3"
              label="Value"
              name="value"
              :lang="values.lang"
              :value="values.value"
              v-on="{ input, focus }"
              @blur="handleCodeBlur"
            />
          </template>
        </form>
      </template>
    </Form>
  </Modal>
</template>

<script>
import { defineComponent } from "vue";
import { object, string } from "yup";
import Modal from "@/components/common/Modal";
import Button from "@/components/common/Button";
import form from "@/mixins/form";

export default defineComponent({
  mixins: [form],
  emits: ["close", "update"],

  components: {
    Button,
    Modal,
  },

  props: {
    field: Object,
    transform: Object,
    connection: Object,
    links: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    fields() {
      return this.links.map((i) => i.source);
    },
    activeFunction() {
      return this.functions.find((i) => i._id === this.activeFunctionId);
    },
    formValues() {
      return (
        this.transform?.fx ?? {
          mode: 0,
          value: "",
          lang: "true_art",
        }
      );
    },
  },

  data() {
    return {
      code: null,
      loading: true,
      functions: [],
      selectionStart: null,
      activeField: null,
      activeFunctionId: null,
      languages: [{ label: "TrueArt", value: "true_art" }, { label: "JavaScript", value: "javascript" }],
      functionModes: [
        { label: "Standard", value: 0 },
        { label: "Code", value: 1 },
      ],
      schema: object().shape({
        mode: string().label("Mode").required(),
        lang: string().label("Language").required(),
        value: string().label("Value").nullable(),
      }),
    };
  },

  methods: {
    async loadFunctions() {
      this.loading = true;

      try {
        const { data: functions } = await this.$axios.get("/functions");
        this.functions = functions.data;
      } catch (error) {
        //
      }

      this.loading = false;
    },
    handleFieldAdd({ value }, setValue) {
      if (!this.activeField) {
        return;
      }
      const activeField = `[:[${this.activeField}]:]`;
      setValue("value", value.splice(this.selectionStart, 0, activeField));
    },
    handleFunctionAdd({ value }, setValue) {
      value = "";
      if (!this.activeFunction) {
        return;
      }

      const signature = `{:{${this.activeFunction.signature}}:}`;
      setValue("value", value.splice(this.selectionStart, 0, signature));
    },
    handleClose() {
      this.$emit("close");
    },
    handleApply() {
      this.$refs.form.submit();
    },
    handleSubmit({ values }) {
      this.$emit("update", { fx: values });
    },
    handleCodeBlur() {
      this.selectionStart = document.getSelection().focusOffset;
    },
  },

  created() {
    this.loadFunctions();
  },
});
</script>
