import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-purple-700 hover:bg-indigo-700 p-1 rounded-full z-10 cursor-pointer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChipIcon = _resolveComponent("ChipIcon")!

  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle(_ctx.linkStyle),
    class: "absolute flex items-center justify-center"
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_ChipIcon, { class: "w-4 h-4 text-white" })
    ])
  ], 4))
}