<template>
  <div>
    <div
      :key="index"
      v-for="(group, index) in groups"
      :class="{ 'mt-1': index !== 0 }"
    >
      <Header
        :title="group.title"
        :active="tab === group.title"
        @click="switchTab(group)"
      />
      <ul v-if="tab === group.title">
        <ListItem
          :key="idx"
          :text="item.text"
          v-for="(item, idx) in group.items"
          @click="$emit('click', item)"
        />
      </ul>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import Header from "./Header";
import ListItem from "./ListItem";

export default defineComponent({
  emits: ["click"],

  components: {
    Header,
    ListItem,
  },

  props: {
    active: String,
    groups: Array,
  },

  setup(props) {
    const tab = ref(props.active);

    const switchTab = (group) => {
      tab.value = group.title;
    };

    return {
      tab,
      switchTab,
    };
  },
});
</script>
