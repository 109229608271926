<template>
  <li>
    <a
      :href="item.href"
      class="block hover:bg-primary-lighter"
      @click.prevent="$emit('select')"
      :class="{ 'bg-primary-lighter': selected }"
    >
      <div class="flex items-center px-4 py-4 sm:px-6">
        <div class="min-w-0 flex-1 flex items-center">
          <div class="flex-shrink-0">
            <img
              class="h-16 w-16"
              :src="adapterIcon"
              :alt="item.connection.name"
            />
          </div>
          <div class="min-w-0 flex-1 px-4 md:grid md:gap-4">
            <div>
              <p class="text-sm font-medium border-primary truncate">
                {{ item.connection.name }}
              </p>
              <div class="mt-2 flex items-center text-sm text-secondary-dark">
                <img
                  class="h-6 w-6"
                  :src="typeIcon"
                  :alt="item.connection.name"
                />
                <div class="ml-3">
                  Created at
                  <Timestamp :value="item.created_at" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <Spinner v-if="loading" class="h-5 w-5 text-gray-400" />

          <ChevronRightIcon
            v-else
            class="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </div>
      </div>
    </a>
  </li>
</template>

<script>
import { defineComponent } from "vue";
import { ChevronRightIcon } from "@heroicons/vue/solid";
import Spinner from "@/components/common/Spinner";
import Timestamp from "@/components/common/Timestamp";
import icons from "@/assets/icons.svg";

export default defineComponent({
  emits: ["select"],

  components: {
    Spinner,
    Timestamp,
    ChevronRightIcon,
  },

  props: {
    item: Object,
    loading: Boolean,
    selected: Boolean,
  },

  computed: {
    adapterIcon() {
      try {
        return `${icons}#${this.item.connection.adapter.icon}`;
      } catch (error) {
        return null;
      }
    },
    typeIcon() {
      try {
        return `${icons}#${this.item.connection.type.icon}`;
      } catch (error) {
        return null;
      }
    },
  },
});
</script>
