
import * as d3 from "d3";
import { v4 as uuidV4 } from "uuid";
import { defineComponent, onMounted, onBeforeUnmount } from "vue";
import { ref, toRefs, computed, provide, inject } from "vue";
import { NodeStatus } from "./flow";
import Toolbox from "./toolbox/Toolbox.vue";
import Link from "./Link.vue";
import Node from "./Node.vue";
import Group from "./Group.vue";
import LinkMarker from "./LinkMarker.vue";
import PendingLink from "./PendingLink.vue";
import { NodeType, LinkPosition } from "./flow";

export default defineComponent({
  emits: ["links:select"],

  components: {
    Link,
    Node,
    Group,
    Toolbox,
    LinkMarker,
    PendingLink,
  },

  setup(props, context) {
    const hovered = ref(false);

    const chart = useChart();
    const cursor = useCursor();
    const chartNodes = useNodes();
    const chartLinks = useLinks(context);
    const chartActions = useActions(chartLinks.createLink);

    provide("cursor", cursor);

    return {
      ...chart,
      ...chartNodes,
      ...chartLinks,
      ...chartActions,
    };
  },
});

function useChart() {
  const chart = ref<any>(null);
  const boundary = ref({ width: 0, height: 0 });

  const boundaryStyle = computed(() => {
    return {
      width: `${boundary.value.width}px`,
      height: `${boundary.value.height}px`,
    };
  });

  function renderChart() {
    if (!chart.value) {
      chart.value = d3.select("#chart") as any;
    }

    const { offsetTop, offsetWidth } = chart.value?.node();
    boundary.value = {
      width: offsetWidth,
      height: window.innerHeight - offsetTop - 20,
    };
  }

  onMounted(() => {
    renderChart();
    window.addEventListener("resize", renderChart);
  });

  onBeforeUnmount(() => {
    window.addEventListener("resize", renderChart);
  });

  return {
    boundary,
    boundaryStyle,
  };
}

function useCursor() {
  const cursor = ref<any>(null);
  const element = ref<any>(null);

  function cursorCordinate(event: any) {
    const boundingClientRect = event.currentTarget?.getBoundingClientRect();

    if (boundingClientRect) {
      const actualX = event.pageX - boundingClientRect.left - window.scrollX;
      const actualY = event.pageY - boundingClientRect.top - window.scrollY;

      cursor.value = {
        x: Math.trunc(actualX),
        y: Math.trunc(actualY),
      };
    }
  }

  onMounted(() => {
    element.value = document.getElementById("chart");
    if (element.value) {
      element.value.addEventListener("mousemove", cursorCordinate);
    }
  });

  onBeforeUnmount(() => {
    if (element.value) {
      element.value.removeEventListener("mouseup", cursorCordinate);
    }
  });

  return cursor;
}

function useNodes() {
  const nodes = inject<any>("nodes");

  const chartNodes = computed(() => {
    return nodes.value.filter((i: any) => i.type !== NodeType.Group);
  });

  const chartGroups = computed(() => {
    return nodes.value.filter((i: any) => i.type === NodeType.Group);
  });

  return {
    chartNodes,
    chartGroups,
  };
}

function useLinks(context: any) {
  const activeLinkId = ref<any>(null);

  const nodes = inject<any>("nodes");
  const links = inject<any>("links");
  const axios = inject<any>("axios");
  const workflowId = inject<string>("workflowId");

  const chartLinks = computed(() => {
    return links.value
      .map((l: any) => {
        l.source = nodes.value.find((n: any) => n._id === l.source_id);
        l.destination = nodes.value.find(
          (n: any) => n._id === l.destination_id
        );
        return l;
      })
      .filter((i: any) => i.source && i.destination);
  });

  const configureLinks = computed(() => {
    return chartLinks.value.filter(
      (i: any) => i.destination.type === NodeType.Fulfill
    );
  });

  async function createLink(
    sourceId: string,
    destinationId: string,
    sourcePosition = "right",
    destinationPosition = "left"
  ) {
    const linkId = uuidV4();

    const values = {
      source_id: sourceId,
      destination_id: destinationId,
      source_properties: {
        position: sourcePosition,
      },
      destination_properties: {
        position: destinationPosition,
      },
    };

    links.value = [...links.value, { _id: linkId, ...values }];

    try {
      const { data: link } = await axios.post(
        `/workflows/${workflowId}/links`,
        values
      );

      links.value = links.value.map((i: any) =>
        i._id === linkId ? link.data : i
      );
    } catch (error) {
      links.value = links.value.filter((i: any) => i._id !== linkId);
    }
  }

  function handleLinkClick(link: any) {
    if (activeLinkId.value && activeLinkId.value === link._id) {
      activeLinkId.value = null;
      context.emit("links:select", link._id);
    } else {
      activeLinkId.value = link._id;
    }
  }

  return {
    chartLinks,
    createLink,
    configureLinks,
    handleLinkClick,
  };
}

function useActions(createLink: any) {
  const pendingId = ref<any>(null);
  const pendingPosition = ref<any>(null);

  const nodes = inject<any>("nodes");

  const pendingLink = computed(() => {
    return nodes.value.find((i: any) => i._id === pendingId.value);
  });

  function handleLink(node: any, position: string) {
    if (node.status !== NodeStatus.Active) {
      return;
    }
    if (!pendingId.value) {
      pendingId.value = node._id;
      pendingPosition.value = position ?? LinkPosition.Right;
    } else if (pendingId.value === node._id) {
      pendingId.value = null;
      pendingPosition.value = null;
    } else {
      createLink(pendingId.value, node._id, pendingPosition.value, position);
      pendingId.value = null;
      pendingPosition.value = null;
    }
  }

  function handlePendingClose() {
    pendingId.value = null;
    pendingPosition.value = null;
  }

  return {
    handleLink,
    pendingLink,
    pendingPosition,
    handlePendingClose,
  };
}
