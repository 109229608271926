<template>
  <Form ref="form" :schema="schema" :value="formValues" @submit="handleUpdate">
    <template #default="{ submit, values, setValue }">
      <form @submit.prevent="submit">
        <Steps :steps="steps" :active="activeStep" @change="handleStepChange" />

        <div class="mt-3">
          <TriggerList
            v-if="activeStep === 1"
            :selected="values.trigger_id"
            @change="handleTriggerChange($event, setValue)"
          />
          <ActionList
            v-if="activeStep === 2"
            :selected="values.action"
            :trigger-id="values.trigger_id"
            @change="handleActionChange($event, setValue)"
          />
          <ConnectionList
            v-if="activeStep === 3"
            :selected="values.connection_id"
            :trigger-id="values.trigger_id"
            @change="setValue('connection_id', $event._id)"
          />
        </div>
      </form>
    </template>
  </Form>
</template>

<script>
import { defineComponent } from "vue";
import { object, string } from "yup";
import Steps from "@/components/common/Steps";
import TriggerList from "@/components/workflows/triggers/List";
import ActionList from "@/components/workflows/triggers/ActionList";
import ConnectionList from "@/components/workflows/connections/TriggerList";
import form from "@/mixins/form";

const steps = [
  {
    id: 1,
    name: "Select trigger",
    description: "Select a trigger.",
  },
  {
    id: 2,
    name: "Select action",
    description: "Select an action.",
  },
  {
    id: 3,
    name: "Select connection",
    description: "Select connection.",
  },
];

export default defineComponent({
  mixins: [form],
  emits: ["submit"],

  components: {
    Steps,
    ActionList,
    TriggerList,
    ConnectionList,
  },

  props: {
    node: Object,
  },

  computed: {
    schema() {
      return object().shape({
        action: string().nullable().required(),
        trigger_id: string().nullable().required(),
        connection_id: string().nullable().required(),
      });
    },
    formValues() {
      return {
        action: this.node?.action ?? null,
        trigger_id: this.node?.trigger_id ?? null,
        connection_id: this.node?.connection_id ?? null,
      };
    },
  },

  data() {
    return {
      steps,
      activeStep: 1,
    };
  },

  methods: {
    handleUpdate({ values, ...helpers }) {
      values = {
        trigger_id: values.trigger_id,
        connection_id: values.connection_id,
        trigger_values: {
          action: values.action,
        },
      };

      this.$emit("submit", { ...helpers, values });
    },
    submit() {
      this.$refs.form.submit();
    },
    handleStepChange(stepId) {
      this.activeStep = stepId;
    },
    handleTriggerChange(trigger, setValue) {
      setValue("trigger_id", trigger._id);
      this.activeStep = 2;
    },
    handleActionChange(action, setValue) {
      setValue("action", action);
      this.activeStep = 3;
    },
  },
});
</script>
