<template>
  <div>
    <Textbox @input="search" placeholder="Search connection types." />

    <div v-if="itemsLoading" class="flex justify-center py-6">
      <Spinner class="w-10 h-10 text-gray-400" />
    </div>

    <ul class="divide-y divide-gray-200 mt-3" v-else>
      <ListItem
        v-for="item in items"
        :key="item._id"
        :item="item"
        :selected="item._id === selectedId"
        :loading="item._id === selectedId && itemLoading"
        @select="handleSelect(item)"
      />
    </ul>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
import { defineComponent } from "vue";
import Textbox from "@/components/common/fields/Textbox";
import Spinner from "@/components/common/Spinner";
import ListItem from "./TransformItem";

export default defineComponent({
  emits: ["update"],

  components: {
    Textbox,
    Spinner,
    ListItem,
  },

  props: {
    link: Object,
    source: Object,
    workflow: Object,
    destination: Object,
  },

  data() {
    return {
      items: [],
      query: "",
      selectedId: null,
      itemLoading: false,
      itemsLoading: true,
    };
  },

  methods: {
    async load() {
      this.itemsLoading = true;

      try {
        const { data: items } = await this.$axios.get(
          `/workflows/${this.workflow._id}/links/${this.link._id}/parent-nodes`
        );

        this.items = items.data;
      } catch (error) {
        //
      }

      this.itemsLoading = false;
    },

    search: debounce(async function (event) {
      this.query = event?.target.value ?? "";
    }, 300),

    async handleSelect(item) {
      this.selectedId = item._id;
      this.itemLoading = true;

      try {
        const values = {
          provider_id: this.selectedId,
        };

        await this.$axios.put(
          `/workflows/${this.workflow._id}/links/${this.link._id}`,
          values
        );

        this.$emit("update", values);
      } catch (error) {
        this.selectedId = null;
      }

      this.itemLoading = false;
    },
  },

  created() {
    this.load();
    this.selectedId = this.link?.provider_id;
  },
});
</script>
