<template>
  <component
    v-if="visible"
    v-bind="params"
    v-on="{ input, blur }"
    :is="field.component"
  />
</template>

<script>
import get from "lodash/get";
import omit from "lodash/omit";
import { defineComponent } from "vue";
import fields from "@/components/common/fields";
import { getFieldName } from "@/lib/field";

export default defineComponent({
  mixins: [fields],

  props: {
    field: Object,
    values: Object,
    prefix: String,
    submitted: Boolean,
    errors: Function,
    touched: Function,
    untouch: Function,
    input: Function,
    setValue: Function,
    setTouched: Function,
    requestPayload: Function,
    dynamic: Object,
  },

  computed: {
    fieldName() {
      return this.prefix
        ? getFieldName(this.field, this.prefix)
        : this.field.name;
    },

    params() {
      return {
        ...this.field.props,
        name: this.fieldName,
        request: this.request,
        label: this.field.label,
        createRequest: this.createRequest,
        error: this.errors(this.fieldName),
        value: get(this.values, this.fieldName),
        required: (this.field.validation ?? []).includes("required"),
      };
    },
    request() {
      return this.createRequest();
    },
    visible() {
      return (
        this.field.dependents?.every((dependent) => {
          let valid = true;
          if (dependent.type === "props") {
            valid = get(this.dynamic, dependent.name) === dependent.value;
          } else {
            const value = get(
              this.values,
              getFieldName(dependent, this.prefix)
            );
            switch (dependent.condition) {
              default:
                valid = Array.isArray(dependent.value)
                  ? dependent.value.indexOf(value) > -1
                  : value === dependent.value;
                break;
            }
          }

          return valid;
        }) ?? true
      );
    },
  },

  methods: {
    blur() {
      this.setTouched(this.fieldName);
    },
    createRequest() {
      if (!this.field.request) {
        return;
      }

      let data = omit(this.values, this.fieldName);
      if (this.requestPayload) {
        data = this.requestPayload(data);
      }

      return { ...this.field.request, data };
    },
  },
});
</script>
