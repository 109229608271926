<template>
  <Modal open :title="title" @close="$emit('close')">
    <Steps
      :steps="steps"
      :active="activeStep"
      @change="handleStepChange"
      class="mt-3"
    />

    <div class="mt-3">
      <AdapterList v-if="activeStep === 1" @adapter="handleAdapterChange" />

      <TypeList
        v-else-if="activeStep === 2"
        :adapter="adapter"
        @type="type = $event"
        @adapter-type="handleAdapterTypeChange"
      />

      <Form
        v-else
        :type-id="type._id"
        :adapter-id="adapter._id"
        :type-prefix="type.prefix"
        :adapter-prefix="adapter.prefix"
        :adapter-type-prefix="adapterType.prefix"
        @submit="handleCreate"
      />
    </div>
  </Modal>
</template>

<script>
import { defineComponent } from "vue";
import Modal from "@/components/common/Modal";
import Steps from "@/components/common/Steps";
import AdapterList from "./adapters/List";
import TypeList from "./types/List";
import Form from "./Form";

const steps = [
  {
    id: 1,
    name: "Connectors",
    description: "Select an adapter.",
  },
  {
    id: 2,
    name: "Connection Types",
    description: "Select a connection type.",
  },
  {
    id: 3,
    name: "Create Connection",
    description: "Provide values and create.",
  },
];

export default defineComponent({
  emits: ["close", "created"],

  components: {
    Modal,
    Form,
    Steps,
    AdapterList,
    TypeList,
  },

  computed: {
    action() {
      if (!this.adapter) {
        return null;
      } else {
        return "Back";
      }
    },
    title() {
      if (!this.adapter) {
        return "Select an adapter";
      } else if (!this.type || !this.adapterType) {
        return `Select a type of ${this.adapter.name}`;
      } else {
        return `Create ${this.adapter.name} connection of ${this.type.name}`;
      }
    },
  },

  data() {
    return {
      steps,
      type: null,
      activeStep: 1,
      adapter: null,
      adapterType: null,
    };
  },

  methods: {
    handleAdapterChange(adapter) {
      this.adapter = adapter;
      this.activeStep = 2;
    },
    handleAdapterTypeChange(adapterType) {
      this.adapterType = adapterType;
      this.activeStep = 3;
    },
    handleStepChange(stepId) {
      if (stepId === 3 && this.adapter && this.adapterType) {
        this.activeStep = 3;
        return;
      }

      if (stepId === 2 && this.adapter) {
        this.activeStep = 2;
        return;
      }

      this.activeStep = 1;
    },
    handleSubmit() {
      if (this.type && this.adapterType) {
        this.type = null;
        this.adapterType = null;
      } else if (this.adapter) {
        this.adapter = null;
      }
    },
    async handleCreate({ values, setSubmitting, setSubmitted }) {
      setSubmitting();
      try {
        const { data: connection } = await this.$axios.post(`/connections`, {
          ...values,
          type_id: this.type._id,
          adapter_id: this.adapter._id,
        });
        this.$emit("created", connection.data);
        setSubmitted();
      } catch (error) {
        setSubmitted();
      }
    },
  },
});
</script>
