<template>
  <Modal
    open
    action="Apply"
    title="Static Value"
    @close="handleClose"
    @submit="handleApply"
  >
    <Form
      ref="form"
      :schema="schema"
      :value="formValues"
      @submit="handleSubmit"
    >
      <template #default="{ submit, values, errors, input, blue, focus }">
        <form @submit.prevent="submit" class="mt-3">
          <Textbox
            label="Value"
            name="static"
            :value="values.static"
            :error="errors('static')"
            v-on="{ input, blue, focus }"
          />
        </form>
      </template>
    </Form>
  </Modal>
</template>

<script>
import { defineComponent } from "vue";
import { object, string } from "yup";
import Modal from "@/components/common/Modal";
import form from "@/mixins/form";

export default defineComponent({
  mixins: [form],
  emits: ["close", "update"],

  components: {
    Modal,
  },

  props: {
    field: Object,
    transform: Object,
    connection: Object,
  },

  computed: {
    formValues() {
      return {
        static: this.transform?.static ?? "",
      };
    },
  },

  data() {
    return {
      schema: object().shape({
        static: string().label("Value").nullable(),
      }),
    };
  },

  methods: {
    handleClose() {
      this.$emit("close");
    },
    handleApply() {
      this.$refs.form.submit();
    },
    handleSubmit({ values }) {
      this.$emit("update", values);
    },
  },
});
</script>
