<template>
  <Field :name="name" :label="label" :error="error" :required="required">
    <input
      type="password"
      class="field"
      :name="name"
      :value="value"
      :disabled="disabled"
      :required="required"
      :class="{ 'mt-1': label }"
      :placeholder="placeholder"
      @blur="$emit('blur', $event)"
      @input="$emit('input', $event)"
      @focus="$emit('focus', $event)"
    />
  </Field>
</template>

<script>
import { defineComponent } from "vue";
import Field from "@/components/common/fields/Field";

export default defineComponent({
  emits: ["input", "blur", "focus"],

  components: {
    Field,
  },

  props: {
    name: String,
    label: String,
    value: String,
    error: String,
    required: Boolean,
    disabled: Boolean,
    placeholder: String,
  },
});
</script>
