<template>
  <div>
    <Textbox @input="search" placeholder="Search triggers." />

    <div v-if="itemsLoading" class="flex justify-center py-6">
      <Spinner class="w-10 h-10 text-gray-400" />
    </div>

    <ul class="divide-y divide-gray-200 mt-3" v-else>
      <ActionListItem
        v-for="item in visibleItems"
        :item="item"
        :key="item.key"
        :selected="item.key === selectedKey"
        @select="handleSelect(item)"
      />
    </ul>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
import { defineComponent } from "vue";
import Textbox from "@/components/common/fields/Textbox";
import Spinner from "@/components/common/Spinner";
import ActionListItem from "./ActionListItem";

export default defineComponent({
  emits: ["change"],

  components: {
    Textbox,
    Spinner,
    ActionListItem,
  },

  props: {
    selected: String,
    triggerId: String,
  },

  computed: {
    visibleItems() {
      return this.items.filter((i) =>
        i.name.toLowerCase().includes(this.query.toLowerCase())
      );
    },
  },

  data() {
    return {
      query: "",
      items: [],
      selectedKey: null,
      itemsLoading: true,
    };
  },

  methods: {
    load: debounce(async function () {
      this.itemsLoading = true;

      try {
        const { data: trigger } = await this.$axios.get(
          `/triggers/${this.triggerId}`
        );

        this.items = trigger.data.actions;
      } catch (error) {
        console.log(error);
      }

      this.itemsLoading = false;
    }, 300),

    search: debounce(function (event) {
      this.query = event?.target.value;
    }, 300),

    handleSelect(item) {
      this.$emit("change", item.key);
    },
  },

  created() {
    this.load();
    this.selectedKey = this.selected;
  },

  watch: {
    selected(value) {
      this.selectedKey = value;
    },
  },
});
</script>
