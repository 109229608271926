import { defineComponent } from "vue";
import fields from "@/components/common/fields";
import Form from "@/components/common/Form.vue";
import Button from "@/components/common/Button.vue";

export default defineComponent({
  mixins: [fields],

  components: {
    Form,
    Button,
  },

  data() {
    return {
      errors: {},
      message: null,
      initialValues: {},
    };
  },

  methods: {
    clearMessage() {
      this.message = null;
    },
    onCancel() {
      this.$router.go(-1);
    },
    handleFormSubmit($event: any) {
      this.$emit("submit", { ...$event, setErrors: this.setErrors });
    },
    setErrors(error: any) {
      this.message = error?.data?.message;
      const form = (this as any).$children.find((i: any) =>
        i.$vnode.tag.includes("Form")
      );

      const errors = error?.data?.errors;
      if (!errors) {
        return;
      }

      const keys = Object.keys(errors);
      Object.values(errors).forEach((items: any, index) => {
        const key = keys[index];
        errors[key] = items[0];
      });

      form?.setErrors(errors);
    },
  },
});
