import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["d"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.path)
    ? (_openBlock(), _createElementBlock("path", {
        key: 0,
        d: _ctx.path,
        fill: "none",
        stroke: "red",
        "stroke-width": "1"
      }, null, 8, _hoisted_1))
    : _createCommentVNode("", true)
}