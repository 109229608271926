<template>
  <div class="flex flex-col">
    <div v-if="loading">loading....</div>
    <template v-else>
      <Title :pages="pages">
        <Button
          class="ml-1"
          status="primary"
          :loading="publishing"
          :disabled="publishing"
          @click="handlePublish"
        >
          {{ publishing ? "Publishing..." : "Publish" }}
        </Button>
      </Title>
      <Playground :workflow="workflow" />
    </template>
  </div>
</template>

<script>
import { defineComponent, ref, inject, computed } from "vue";
import { useRoute } from "vue-router";
import Title from "@/components/common/Title";
import Button from "@/components/common/Button";
import Playground from "@/components/workflows/Playground";

export default defineComponent({
  components: {
    Button,
    Title,
    Playground,
  },
  setup() {
    const route = useRoute();
    const loading = ref(true);
    const workflow = ref(null);
    const publishing = ref(false);
    const $axios = inject("axios");

    const pages = computed(() => [
      {
        name: "Workflows",
        href: "workflows.index",
        current: false,
      },
      {
        name: workflow.value ? `${workflow.value.name}` : "View",
        href: "workflows.view",
        current: false,
      },
      {
        name: "Edit",
        href: "workflows.edit",
        current: true,
      },
    ]);

    (async () => {
      loading.value = true;

      try {
        const [{ data: item }] = await Promise.all([
          $axios.get(`/workflows/${route.params.workflow}`),
        ]);

        workflow.value = item.data;
      } catch (error) {
        //
      }

      loading.value = false;
    })();

    const handlePublish = async () => {
      publishing.value = true;

      try {
        await $axios.post(`/workflows/${route.params.workflow}/publish`);
      } catch (error) {
        //
      }

      publishing.value = false;
    };

    return {
      pages,
      loading,
      workflow,
      publishing,
      handlePublish,
    };
  },
});
</script>
