<template>
  <Field :name="name" :label="label" :error="error" :required="required">
    <div
      v-bind="getRootProps()"
      class="
        border-2 border-dashed
        p-3
        rounded
        flex flex-col
        justify-center
        items-center
      "
      :class="[
        isDragActive
          ? 'bg-blue-50 border-blue-300'
          : error
          ? 'bg-red-50 border-red-300'
          : 'bg-gray-50',
      ]"
    >
      <div class="p-3 text-center">
        <input
          class="field"
          :name="name"
          :disabled="disabled"
          :required="required"
          :class="{ 'mt-1': label }"
          :placeholder="placeholder"
          @blur="$emit('blur', $event)"
          @input="$emit('input', $event)"
          @focus="$emit('focus', $event)"
          v-bind="getInputProps()"
        />
        <p v-if="isDragActive">Drop the files here ...</p>
        <p v-else>Drag 'n' drop some files here, or click to select files</p>
      </div>
      <Button @click="open">Open</Button>
    </div>
    <div v-if="files.length">
      <div
        :key="index"
        v-for="(file, index) in files"
        class="
          py-1
          px-2
          bg-gray-100
          rounded
          mt-3
          flex
          justify-between
          items-center
        "
      >
        <div class="flex justify-between items-center">
          <DocumentIcon class="w-5 h-5" />
          <span class="ml-3">{{ file?.name }}</span>
        </div>
        <TrashIcon
          class="w-5 h-5 text-red-600 cursor-pointer"
          @click="onRemove(index)"
        />
      </div>
    </div>
  </Field>
</template>

<script>
import { defineComponent, toRefs, computed } from "vue";
import { useDropzone } from "vue3-dropzone";
import { TrashIcon, DocumentIcon } from "@heroicons/vue/outline";
import Button from "@/components/common/Button";
import Field from "@/components/common/fields/Field";

export default defineComponent({
  emits: ["input", "blur", "focus"],

  components: {
    Button,
    Field,
    TrashIcon,
    DocumentIcon,
  },

  props: {
    name: String,
    label: String,
    error: String,
    multiple: Boolean,
    disabled: Boolean,
    required: Boolean,
    placeholder: String,
    accept: [String, Array],
    value: [Object, Array, String],
  },

  setup(props, context) {
    const { value, multiple, accept } = toRefs(props);

    const files = computed(() => {
      if (!value.value) {
        return [];
      }

      return multiple.value ? value.value : [value.value];
    });

    function onDrop(acceptFiles) {
      if (props.multiple) {
        context.emit("input", acceptFiles, props.name);
      } else {
        context.emit("input", acceptFiles[0], props.name);
      }
    }

    function onRemove(index) {
      if (props.multiple) {
        context.emit(
          "input",
          value.value.filter((i, idx) => idx === index),
          props.name
        );
      } else {
        context.emit("input", null, props.name);
      }
    }

    const dropzone = useDropzone({
      onDrop,
      accept,
    });

    return {
      files,
      onRemove,
      ...dropzone,
    };
  },
});
</script>
