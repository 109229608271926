<template>
  <Modal :open="open" title="Connections" @close="$emit('close')">
    <Textbox @input="load" placeholder="Search connection." />

    <div v-if="connectionsLoading" class="flex justify-center py-6">
      <Spinner class="w-10 h-10 text-gray-400" />
    </div>

    <ul class="divide-y divide-gray-200 mt-3" v-else>
      <ListItem
        v-for="item in items"
        :key="item._id"
        :item="item"
        :selected="item._id === selectedId"
        :loading="item._id === selectedId && connectionLoading"
        @select="handleSelect(item)"
      />
    </ul>

    <template #buttons>
      <Button type="button" @click="handleCreate"> Create Connection </Button>
    </template>
  </Modal>
</template>

<script>
import debounce from "lodash/debounce";
import { defineComponent } from "vue";
import Textbox from "@/components/common/fields/Textbox";
import Spinner from "@/components/common/Spinner";
import Button from "@/components/common/Button";
import Modal from "@/components/common/Modal";
import ListItem from "./ListItem";

export default defineComponent({
  emits: ["close", "select"],

  components: {
    Button,
    Modal,
    Textbox,
    Spinner,
    ListItem,
  },

  props: {
    open: Boolean,
  },

  data() {
    return {
      items: [],
      selectedId: null,
      connectionLoading: false,
      connectionsLoading: true,
    };
  },

  methods: {
    load: debounce(async function (event) {
      this.connectionsLoading = true;

      try {
        const { data: items } = await this.$axios.get("/connections", {
          params: {
            per_page: 5,
            "filters[name]": event?.target.value ?? "",
          },
        });

        this.items = items.data;
      } catch (error) {
        // console.log(error);
      }

      this.connectionsLoading = false;
    }, 300),

    async handleSelect(item) {
      this.selectedId = item._id;
      this.connectionLoading = true;

      try {
        const [{ data: connection }] = await Promise.all([
          this.$axios.get(`/connections/${this.selectedId}`),
        ]);

        this.$emit("select", connection.data);
        this.selectedId = null;
      } catch (error) {
        this.selectedId = null;
      }

      this.connectionLoading = false;
    },
    handleCreate() {
      this.$emit("close");
      setTimeout(() => {
        this.$emit("create");
      }, 300);
    },
  },

  created() {
    this.load();
  },
});
</script>
