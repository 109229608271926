<template>
  <div
    class="absolute top-half"
    :class="{
      '-left-half': position === 'left',
      '-right-half': position === 'right',
    }"
    @click="$emit('link', position)"
  >
    <div class="cc-node bg-white border-2 border-black h-4 rounded-full w-4" />
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    position: {
      type: String,
      default: "right",
    },
  },

  setup() {
    function handleClick(e) {
      e.stopPropagation();
    }

    return {
      handleClick,
    };
  },
});
</script>

<style scoped>
.top-half {
  top: calc(50% - 8px);
}

.-right-half {
  right: -0.4rem;
}

.-left-half {
  left: -0.45rem;
}

.cc-node:hover {
  background-color: #ee3696
}
</style>
