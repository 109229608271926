<template>
  <Lookup
    :field="field"
    :links="links"
    :transform="transform"
    :connection="connection"
    @close="$emit('close')"
    @update="$emit('update', $event)"
  />
</template>

<script>
import { defineComponent } from "vue";
import Lookup from "./Lookup";

export default defineComponent({
  emits: ["close", "update"],

  components: {
    Lookup,
  },

  props: {
    field: Object,
    transform: Object,
    connection: Object,
    links: {
      type: Array,
      default: () => [],
    },
  },
});
</script>
