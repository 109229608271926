<template>
  <div>
    <div class="sm:hidden">
      <label for="tabs" class="sr-only">Select a tab</label>
      <select
        id="tabs"
        @change="handleChange($event.target.value)"
        class="
          block
          w-full
          pl-3
          pr-10
          py-2
          text-base
          border-secondary-light
          focus:outline-none focus:ring-primary-dark focus:border-primary-dark
          sm:text-sm
          rounded-md
        "
      >
        <option
          v-for="tab in tabs"
          :key="tab.label"
          :value="tab.value"
          :selected="tab.value === value"
        >
          {{ tab.label }}
        </option>
      </select>
    </div>
    <div class="hidden sm:block">
      <div class="border-b border-gray-200">
        <nav class="-mb-px flex space-x-8" aria-label="Tabs">
          <a
            v-for="tab in tabs"
            :key="tab.label"
            :href="tab.label"
            @click.prevent="handleChange(tab.value)"
            :class="[
              tab.value === value
                ? 'border-primary-dark'
                : 'border-transparent text-secondary-dark hover:text-gray-700 hover:border-secondary-light',
              'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm',
            ]"
            :aria-current="tab.value === value ? 'page' : undefined"
          >
            {{ tab.label }}
          </a>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  emits: ["input", "blur", "focus", "change"],

  props: {
    name: String,
    label: String,
    value: [Number, String],
    error: String,
    disabled: Boolean,
    placeholder: String,
    required: Boolean,
    tabs: Array,
  },

  methods: {
    handleChange(value) {
      this.$emit("input", value, this.name);
      this.$emit("change");
    },
  },
});
</script>
