<template>
  <div>
    <Textbox @input="search" placeholder="Search connection." />

    <div v-if="itemsLoading" class="flex justify-center py-6">
      <Spinner class="w-10 h-10 text-gray-400" />
    </div>

    <ul class="divide-y divide-gray-200 mt-3" v-else>
      <ListItem
        v-for="item in items"
        :item="item"
        :key="item._id"
        :selected="item._id === selectedId"
        :loading="item._id === selectedId && itemLoading"
        @select="handleSelect(item)"
      />
    </ul>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
import { defineComponent } from "vue";
import Textbox from "@/components/common/fields/Textbox";
import Spinner from "@/components/common/Spinner";
import ListItem from "./ListItem";

export default defineComponent({
  emits: ["change"],

  components: {
    Textbox,
    Spinner,
    ListItem,
  },

  props: {
    selected: String,
    triggerId: String,
  },

  data() {
    return {
      items: [],
      trigger: null,
      selectedId: null,
      itemLoading: false,
      itemsLoading: true,
    };
  },

  methods: {
    async load() {
      this.itemsLoading = true;

      try {
        const { data: trigger } = await this.$axios.get(
          `/triggers/${this.triggerId}`
        );

        this.trigger = trigger.data;
        this.search();
      } catch (error) {
        this.itemsLoading = false;
      }
    },
    search: debounce(async function (event) {
      this.itemsLoading = true;

      try {
        const { data: items } = await this.$axios.get("/connections", {
          params: {
            "filters[name]": event?.target.value ?? "",
            "filters[adapter_id]": this.trigger.adapter_id,
          },
        });

        this.items = items.data;
      } catch (error) {
        //
      }

      this.itemsLoading = false;
    }, 300),

    async handleSelect(item) {
      this.selectedId = item._id;
      this.itemLoading = true;

      try {
        const { data: item } = await this.$axios.get(
          `/connections/${this.selectedId}`
        );

        this.$emit("change", item.data);
      } catch (error) {
        this.selectedId = null;
      }

      this.itemLoading = false;
    },
  },

  created() {
    this.load();
    this.selectedId = this.selected;
  },

  watch: {
    selected(value) {
      this.selectedId = value;
    },
  },
});
</script>
