<template>
  <li>
    <a
      :href="item.href"
      class="block hover:bg-primary-lighter"
      @click.prevent="$emit('select')"
      :class="{ 'bg-primary-lighter': selected }"
    >
      <div class="flex items-center px-4 py-4 sm:px-6">
        <div class="min-w-0 flex-1 flex items-center">
          <div class="flex-shrink-0">
            <img class="h-16 w-16" :src="filename" :alt="item.name" />
          </div>
          <div class="min-w-0 flex-1 px-4 md:grid md:gap-4">
            <div>
              <p class="text-sm font-medium border-primary truncate">
                {{ item.name }}
              </p>
              <p
                class="mt-2 flex items-center text-sm text-secondary-dark-dark"
              >
                <CheckCircleIcon
                  class="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
                  aria-hidden="true"
                />
                Created at
                {{ item.created_at }}
              </p>
            </div>
          </div>
        </div>
        <div>
          <Spinner v-if="loading" class="h-5 w-5 text-gray-400" />

          <ChevronRightIcon
            v-else
            class="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </div>
      </div>
    </a>
  </li>
</template>

<script>
import { defineComponent } from "vue";
import { CheckCircleIcon, ChevronRightIcon } from "@heroicons/vue/solid";
import Spinner from "@/components/common/Spinner";
import icons from "@/assets/icons.svg";

export default defineComponent({
  emits: ["select"],

  components: {
    Spinner,
    CheckCircleIcon,
    ChevronRightIcon,
  },

  props: {
    item: Object,
    loading: Boolean,
    selected: Boolean,
  },

  computed: {
    filename() {
      try {
        return `${icons}#${this.item.icon}`;
      } catch (error) {
        return null;
      }
    },
  },
});
</script>
