<template>
  <Button
    center
    :loading="creating"
    v-tooltip="'Create Node'"
    @click="promptSelect = true"
  >
    <ServerIcon class="w-5 h-5" v-if="!creating" />
  </Button>

  <Select
    v-if="promptSelect"
    :open="promptSelect"
    @select="handle"
    @close="promptSelect = false"
    @create="promptCreate = true"
  />

  <Create v-if="promptCreate" @created="handle" @close="promptCreate = false" />
</template>

<script>
import { defineComponent, ref, inject } from "vue";
import { ServerIcon } from "@heroicons/vue/outline";
import Button from "@/components/common/Button";
import Create from "@/components/connections/Create";
import Select from "@/components/workflows/connections/Add";
import { NODE_WIDTH, NODE_HEIGHT } from "../flow";

export default defineComponent({
  components: {
    Button,
    Create,
    Select,
    ServerIcon,
  },

  setup() {
    const creating = ref(false);
    const promptCreate = ref(false);
    const promptSelect = ref(false);

    const axios = inject("axios");
    const nodes = inject("nodes");
    const workflowId = inject("workflowId");

    async function handle(connection) {
      creating.value = true;
      promptCreate.value = false;
      promptSelect.value = false;

      try {
        const { data: item } = await axios.post(
          `/workflows/${workflowId}/nodes`,
          {
            x: NODE_WIDTH * 2,
            y: NODE_HEIGHT * 2,
            type: "Fulfill",
            status: "Draft",
            width: NODE_WIDTH,
            height: NODE_HEIGHT,
            connection_id: connection._id,
          }
        );

        nodes.value = [...nodes.value, item.data];
      } catch (error) {
        console.log("error", error);
      }

      creating.value = false;
    }

    return {
      handle,
      creating,
      promptCreate,
      promptSelect,
    };
  },
});
</script>
