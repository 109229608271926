
import * as d3 from "d3";
import { defineComponent, onMounted, onBeforeUnmount } from "vue";
import { computed, toRefs, ref, inject } from "vue";
import { linkSourceCoordinate, LinkPosition } from "./flow";

export default defineComponent({
  emits: ["close"],

  props: {
    node: Object,
    position: String,
  },

  setup(props, conext) {
    const { node } = toRefs(props);
    const cursor = inject<any>("cursor");

    const path = computed(() => {
      if (!cursor.value) {
        return null;
      }

      return d3.linkHorizontal()({
        source: linkSourceCoordinate(node.value, props.position as any),
        target: [cursor.value.x, cursor.value.y],
      });
    });

    function handleClick() {
      conext.emit("close");
    }

    onMounted(() => {
      window.addEventListener("mouseup", handleClick);
    });

    onBeforeUnmount(() => {
      window.removeEventListener("click", handleClick);
    });

    return {
      path,
      cursor,
    };
  },
});
