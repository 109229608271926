<template>
  <div>
    <MenuItem v-slot="{ active }" @click="openModal()">
      <a
        href="#"
        :class="[
          'group flex items-center px-4 py-2 text-sm ',
          active ? 'bg-gray-100 text-gray-800' : 'text-gray-700',
        ]"
      >
        <CogIcon class="mr-3 h-5 w-5" aria-hidden="true" />
        Configure
      </a>
    </MenuItem>

    <Modal
      :open="prompt"
      :action="action"
      :loading="submitting"
      @close="prompt = false"
      @submit="handleSubmit"
    >
      <template v-slot:buttonsMiddle>
        <button
          v-if="step > 1"
          type="button"
          class="
            order-0
            inline-flex
            items-center
            px-4
            py-2
            border
            shadow-sm
            text-sm
            font-medium
            rounded-md
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            sm:order-1
            disabled:opacity-50
            bg-white
            text-gray-700
            hover:bg-secondary-lighter
            focus:ring-primary
            border-secondary-light
            sm:ml-3
          "
          @click="step = --step">
          Back
        </button>
        <button
          v-if="step <= 1"
          type="button"
          class="
            order-0
            inline-flex
            items-center
            px-4
            py-2
            border
            shadow-sm
            text-sm
            font-medium
            rounded-md
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            sm:order-1
            disabled:opacity-50
            text-white
            bg-primary
            hover:bg-primary-darker
            focus:ring-primary-dark
            border-transparent
            sm:ml-3
          "
          @click="step = ++step">
          Next
        </button>
      </template>

      <component ref="form" :node="node" :is="node.type" :step="step" @submit="handleUpdate">
        <div class="flex text-gray-900">
          <h2 class="flex flex-1 font-medium items-center leading-4 text-lg">
            Configure {{ node.connection?.name }}
          </h2>
          <h2 class="flex flex-none justify-end font-medium items-center leading-4 text-lg">
            <SwitchTypeButton :node="node" />
          </h2>
        </div>
      </component>
    </Modal>
  </div>
</template>

<script>
import { defineComponent, ref, inject, watch } from "vue";
import { CogIcon } from "@heroicons/vue/solid";
import { MenuItem } from "@headlessui/vue";
import Modal from "@/components/common/Modal";
import SwitchTypeButton from "./SwitchTypeButton";
import { NodeStatus } from "../flow";
import Fetch from "./activate/Fetch";
import Listen from "./activate/Listen";
import Fulfill from "./activate/Fulfill";

export default defineComponent({
  components: {
    CogIcon,
    Modal,
    Fetch,
    Listen,
    Fulfill,
    MenuItem,
    SwitchTypeButton,
  },

  props: {
    node: Object,
  },

  setup(props) {
    const firstStep = ref(1);
    const lastStep = ref(2);
    const step = ref(1);
    const action = ref(null);
    const form = ref(null);
    const prompt = ref(false);
    const submitting = ref(false);

    const axios = inject("axios");
    const nodes = inject("nodes");
    const workflowId = inject("workflowId");

    async function handleUpdate({ values, setSubmitting, setSubmitted }) {
      setSubmitting();
      submitting.value = true;

      try {
        values = {
          ...values,
          status: NodeStatus.Active,
        };

        await axios.put(
          `/workflows/${workflowId}/nodes/${props.node._id}`,
          values
        );

        const { data: node } = await axios.get(
          `/workflows/${workflowId}/nodes/${props.node._id}`
        );

        nodes.value = nodes.value.map((i) =>
          i._id === props.node._id ? node.data : i
        );

        prompt.value = false;
      } catch (error) {
        //
      }

      setSubmitted();
      submitting.value = false;
    }

    function handleSubmit() {
      form.value.submit();
    }

    return {
      firstStep,
      lastStep,
      action,
      step,
      form,
      prompt,
      submitting,
      handleSubmit,
      handleUpdate,
    };
  },

  methods: {
    openModal() {
      this.step = this.firstStep;
      this.prompt = true
      return true;
    }
  },

  watch: {
    step(newVal, oldVal) {
      if (newVal == this.lastStep) {
        this.action = "Activate";
      } else {
        this.action = "";
      }
    }
  }
});
</script>
