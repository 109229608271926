import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = ["d"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("g", { id: _ctx.linkId }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.linker.paths, (path, index) => {
      return (_openBlock(), _createElementBlock("path", {
        d: path,
        key: index,
        "stroke-width": "2",
        class: _normalizeClass({ 'z-10': _ctx.hover }),
        stroke: "#6d28d9"
      }, null, 10, _hoisted_2))
    }), 128))
  ], 8, _hoisted_1))
}