<template>
  <li>
    <a
      :href="item.name"
      class="block hover:bg-primary-lighter"
      @click.prevent="$emit('select', item.name)"
      :class="{ 'bg-primary-lighter': isSelected }"
    >
      <div class="flex items-center px-4 py-4 sm:px-6">
        <div class="min-w-0 flex-1 flex items-center">
          <div class="flex-shrink-0">
            <DatabaseIcon class="h-10 w-10 text-primary" aria-hidden="true" />
          </div>
          <div class="min-w-0 flex-1 px-4 md:grid md:gap-4">
            <div>
              <p class="text-sm font-medium border-primary truncate">
                {{ item.name }}
              </p>
              <div class="flex items-center text-sm text-secondary-dark">
                <div>{{ item.id }}</div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <Spinner class="h-5 w-5 text-gray-400" v-if="isSelected && loading" />

          <ChevronRightIcon
            v-else
            class="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </div>
      </div>
    </a>
  </li>
</template>

<script>
import { defineComponent } from "vue";
import { ChevronRightIcon } from "@heroicons/vue/solid";
import { DatabaseIcon } from "@heroicons/vue/solid";
import Spinner from "@/components/common/Spinner";

export default defineComponent({
  emits: ["select"],

  components: {
    Spinner,
    DatabaseIcon,
    ChevronRightIcon,
  },

  props: {
    item: Object,
    selected: String,
    loading: Boolean,
  },

  computed: {
    isSelected() {
      return this.selected === this.item.name;
    },
  },
});
</script>
