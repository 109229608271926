<template>
  <div :id="nodeId" :style="nodeStyle" class="absolute flex group z-10">
    <div
      class="
        flex-1
        relative
        bg-blue-50 bg-opacity-25
        border-2 border-dashed border-blue-300
        rounded
      "
    >
      <Link v-if="!disabled" position="left" @link="$emit('link', $event)" />
      <Link position="right" @link="$emit('link', $event)" />

      <Expand class="hidden group-hover:flex" />
      <Actions class="hidden group-hover:flex" :group="group" />
    </div>
  </div>
</template>

<script>
import { defineComponent, toRefs } from "vue";
import { useNode } from "./use/node";
import Link from "./actions/Link";
import Expand from "./actions/Expand";
import Actions from "./actions/GroupActions";

export default defineComponent({
  emits: ["link"],

  components: {
    Link,
    Expand,
    Actions,
  },

  props: {
    group: Object,
    active: Boolean,
    boundary: Object,
  },

  setup(props) {
    const { group, boundary } = toRefs(props);
    return useNode(group, boundary);
  },
});
</script>
