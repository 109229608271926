<template>
  <div class="py-3 px-4 sm:px-6 lg:px-8">
    <Button type="button" @click="$emit('close')"> Close </Button>
  </div>

  <div class="py-3 px-4 sm:px-6 lg:px-8">
    <div v-if="loading">Loading...</div>
    <template v-else>
      <Steps
        visit-upcoming
        :steps="steps"
        :active="activeStep"
        @change="handleStepChange"
      />
      <div class="mt-3">
        <Connections
          v-if="activeStep === 1"
          :link="link"
          :source="source"
          :workflow="workflow"
          :destination="destination"
          @update="handleConnectionsUpdate"
        />
        <DataSets
          v-else-if="activeStep === 2"
          :link="link"
          :source="source"
          :workflow="workflow"
          :destination="destination"
          @update="handleDataSetsUpdate"
        />
        <Transform
          v-else-if="activeStep === 3"
          :link="link"
          :source="source"
          :workflow="workflow"
          :destination="destination"
          @update="handleTransformUpdate"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { defineComponent, ref, inject, toRefs } from "vue";
import Button from "@/components/common/Button";
import Steps from "@/components/common/Steps";
import Transform from "@/components/workflows/transform/Index";
import DataSets from "@/components/workflows/datasets/TransformList";
import Connections from "@/components/workflows/nodes/TransformList";

const steps = [
  {
    id: 1,
    name: "Connections",
    description: "Select a connection.",
  },
  {
    id: 2,
    name: "Data Sets",
    description: "Select a data set.",
  },
  {
    id: 3,
    name: "Transform",
    description: "Configure transformation.",
  },
];

export default defineComponent({
  emits: ["close", "update"],

  components: {
    Steps,
    Button,
    DataSets,
    Transform,
    Connections,
  },

  props: {
    link: Object,
    workflow: Object,
  },

  setup(props) {
    let linkStep = 1;
    if (props.link.provider_id) {
      linkStep = 2;

      if (props.link.dataset) {
        linkStep = 3;
      }
    }

    const source = ref(null);
    const loading = ref(true);
    const destination = ref(null);
    const activeStep = ref(linkStep);

    const axios = inject("axios");
    const workflowId = inject("workflowId");

    (async function () {
      loading.value = true;

      try {
        const [{ data: sourceResponse }, { data: destinationResponse }] =
          await Promise.all([
            axios.get(`/workflows/${workflowId}/nodes/${props.link.source_id}`),
            axios.get(
              `/workflows/${workflowId}/nodes/${props.link.destination_id}`
            ),
          ]);

        source.value = sourceResponse.data;
        destination.value = destinationResponse.data;
      } catch (error) {
        console.log("error", error);
        //
      }

      loading.value = false;
    })();

    return {
      steps,
      activeStep,
      loading,
      source,
      destination,
    };
  },

  methods: {
    handleSubmit() {
      this.$emit("save", this.map);
    },
    handleStepChange(stepId) {
      if (stepId === 2 && !this.link.destination_id) {
        return;
      }

      if (stepId === 3 && !this.link.dataset) {
        return;
      }

      this.activeStep = stepId;
    },
    handleConnectionsUpdate(values) {
      this.$emit("update", values);
      this.activeStep = 2;
    },
    handleDataSetsUpdate(values) {
      this.$emit("update", values);
      this.activeStep = 3;
    },
    handleTransformUpdate(values) {
      this.$emit("update", values);
    },
  },
});
</script>
