
import { defineComponent } from "vue";
import { computed, toRefs, inject, watch, ref } from "vue";
import { distanceOfPointToLine, between, line2 } from "@/lib/svg";
import { linkCoordinate, NodeType } from "./flow";

export default defineComponent({
  props: {
    link: Object,
  },

  setup(props) {
    const { link } = toRefs(props);
    const cursor = inject<any>("cursor");

    const hover = ref<any>(false);

    const linkId = computed(() => {
      return `link-${link.value?._id}`;
    });

    const markerId = computed(() => {
      return `marker-${link.value?._id}`;
    });

    const linker = computed(() => {
      const { source, target } = linkCoordinate(link.value);

      const sourceX = source[0];
      const sourceY = source[1];

      const destinationX = target[0];
      const destinationY = target[1];

      const sourcePosition = link.value?.source_properties?.position;
      const destinationPosition = link.value?.destination_properties?.position;

      return line2(
        sourceX,
        sourceY - 3,
        destinationX,
        destinationY - 3,
        sourcePosition,
        destinationPosition,
        link.value?.source.type === NodeType.Group ? "ld" : undefined
      );
    });

    watch(cursor, () => {
      if (!cursor.value) {
        return null;
      }

      const cursorX = cursor.value.x;
      const cursorY = cursor.value.y;

      hover.value = linker.value.lines.some((link) => {
        const distance = distanceOfPointToLine(
          link.sourceX,
          link.sourceY,
          link.destinationX,
          link.destinationY,
          cursorX,
          cursorY
        );

        return (
          distance < 5 &&
          between(link.sourceX - 2, link.destinationX + 2, cursorX) &&
          between(link.sourceY - 2, link.destinationY + 2, cursorY)
        );
      });
    });

    return {
      linker,
      hover,
      linkId,
      markerId,
    };
  },
});
