<template>
  <div v-if="loading">loading....</div>
  <div v-else>
    <Form
      ref="form"
      :schema="schema"
      :value="formValues"
      @submit="handleSubmit"
    >
      <template #default="{ submit, values, errors, input, blue, focus }">
        <form class="border p-3 rounded mt-3" @submit.prevent="submit">
          <Textbox
            name="name"
            label="Name"
            :value="values.name"
            :error="errors('name')"
            v-on="{ input, blue, focus }"
          />
        </form>
      </template>
    </Form>

    <TypeForm
      v-if="visibleType"
      :values="values"
      :type-id="typeId"
      :prefix="typePrefix"
      :submitted="submitted"
      :adapter-id="adapterId"
      :fields="fields.type"
      :adapter-type-prefix="adapterTypePrefix"
      :adapter-type-fields="fields.adapter_type"
      @submit="handleTypeSubmit"
    />

    <AdapterForm
      :name="$refs.form?.values?.name"
      :values="adapterValues"
      :type-id="typeId"
      :prefix="adapterPrefix"
      :adapter-id="adapterId"
      :fields="fields.adapter"
      :open="visibleAdapter"
      @submit="handleAdapterSubmit"
    />
  </div>
</template>

<script>
import moment from "moment";
import { object, string } from "yup";
import { defineComponent } from "vue";
import AdapterForm from "@/components/connections/adapters/Form";
import TypeForm from "@/components/connections/types/Form";
import form from "@/mixins/form";
import { dateTimeFormat } from "@/config/env";

export default defineComponent({
  emits: ["submit"],
  mixins: [form],

  components: {
    TypeForm,
    AdapterForm,
  },

  computed: {
    adapterValues() {
      return { ...this.values, ...this.typeFormValues };
    },
    visibleAdapter() {
      return this.visibleType ? this.submitted : true;
    },
    visibleType() {
      return this.fields.type.length || this.fields.adapter_type.length;
    },
    formValues() {
      return {
        name:
          this.values?.name ??
          `Connection - ${moment().format(dateTimeFormat)}`,
      };
    },
  },

  props: {
    values: Object,
    typeId: String,
    adapterId: String,
    typePrefix: String,
    adapterPrefix: String,
    adapterTypePrefix: String,
  },

  data() {
    return {
      name: null,
      loading: true,
      typeFormValues: {},
      adapterFormValues: {},
      submitted: false,
      setSubmitted: null,
      setSubmitting: null,
      fields: {
        type: [],
        adapter: [],
        adapter_type: [],
      },
      schema: object().shape({
        name: string().label("Name").required(),
      }),
    };
  },

  methods: {
    handleSubmit({ values }) {
      this.$emit("submit", {
        setSubmitted: this.setSubmitted,
        setSubmitting: this.setSubmitting,
        values: {
          ...values,
          values: {
            ...this.typeFormValues,
            ...this.adapterFormValues,
          },
        },
      });
    },
    handleTypeSubmit(values) {
      this.submitted = true;
      this.typeFormValues = values;
    },
    handleAdapterSubmit({ setSubmitted, setSubmitting, values }) {
      this.adapterFormValues = values;
      this.setSubmitted = setSubmitted;
      this.setSubmitting = setSubmitting;
      this.$refs.form.submit();
    },
  },

  async created() {
    this.loading = true;

    try {
      const { data: fields } = await this.$axios.get(
        `/adapters/${this.adapterId}/types/${this.typeId}/fields`
      );

      this.fields = fields.data;
    } catch (error) {
      //
    }

    this.loading = false;
  },
});
</script>
