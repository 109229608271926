<template>
  <div v-if="loading">Loading...</div>
  <Form
    v-else
    ref="form"
    :schema="schema"
    :value="formValues"
    @submit="handleUpdate"
  >
    <template #default="{ submit, ...formParams }">
      <form @submit.prevent="submit">
        <slot />
        <div :style="'display:'+(step == 1 ? 'block' : 'none')+';'">
          <Field
            v-for="field in fields"
            v-bind="formParams"
            :key="field._id"
            :field="field"
            :style="'display:'+((field.step ?? 1) == 1 ? 'block' : 'none')+';'"
            :dynamic="dynamicProps(field)"
            :request-payload="createRequestPayload"
            class="mt-3"
          />
        </div>
        <div :style="'display:'+(step == 2 ? 'block' : 'none')+';'">
          <Field
            v-for="field in fields"
            v-bind="formParams"
            :key="field._id"
            :field="field"
            :style="'display:'+(field.step == 2 ? 'block' : 'none')+';'"
            :dynamic="dynamicProps(field)"
            :request-payload="createRequestPayload"
            class="mt-3"
          />
          <Field
            v-bind="formParams"
            :field="filterField"
            :dynamic="dynamicProps(filterField)"
            :request-payload="createRequestPayload"
            class="mt-3"
          />
        </div>
      </form>
    </template>
  </Form>
  
</template>

<script>
import { defineComponent } from "vue";
import { clean } from "@/lib/helpers";
import pick from "lodash/pick";
import form from "@/mixins/form";
import fields from "@/mixins/fields";
import Field from "@/components/common/Field";

export default defineComponent({
  mixins: [fields, form],
  emits: ["submit"],

  components: {
    Field,
  },

  props: {
    node: Object,
    step: Number,
    workflow: Object,
  },

  computed: {
    schema() {
      return this.createSchema(this.fields);
    },
    formValues() {
      return this.createFieldValues(this.fields, this.node.connection_values);
    },
  },

  data() {
    return {
      fields: [],
      loading: true,
      connection: null,
      filterField: {
        label: "Filter",
        name: "attrs.filter",
        default_value: "",
        component: "Code",
        props: {
          lang: "sql",
        },
      },
    };
  },

  methods: {
    async load() {
      this.loading = true;

      try {
        const { data: connection } = await this.$axios.get(
          `/connections/${this.node.connection_id}`
        );

        const { data: fields } = await this.$axios.get(
          `/adapters/${connection.data.adapter._id}/types/${connection.data.type._id}/workflow-fields`
        );

        this.connection = connection.data;
        this.fields = fields.data.adapter
          .concat(fields.data.type)
          .concat(fields.data.adapter_type)
          .concat([{...this.filterField, dependents: [{
              name: "is_more",
              value: "no-val"
            }],}]);
      } catch (error) {
        //
      }

      this.loading = false;
    },
    handleUpdate({ values, ...helpers }) {
      var vals = clean(values);
      var attrs = vals["attrs"];
      delete vals["attrs"];
      var filter = attrs.filter ?? "";
      if(filter.length > 1){
        attrs.filter = {
          type : "query",
          value : attrs.filter
        }
      } else {
        attrs.filter = null;
      }

      values = {
        connection_values: vals,
        attrs: attrs,
        mode_type: 0,
      };

      this.$emit("submit", { ...helpers, values });
    },
    submit() {
      this.$refs.form.submit();
    },
    dynamicProps(field) {
      return pick(this, field?.props?.dynamic ?? []);
    },
    createRequestPayload() {
      return {
        connection_id: this.connection._id,
      };
    },
  },

  created() {
    this.filterField.default_value = this.node.attrs?.filter?.value ?? ""
    this.load();
  },
});
</script>
