<template>
  <div class="flex justify-end">
    <div>
      <slot />
    </div>
  </div>

  <div class="mt-3">
    <div v-if="loading">loading...</div>
    <Condition
      v-else
      :link="link"
      :workflow="workflow"
      :functions="functions"
      :source-fields="sourceFields"
      :destination-fields="destinationFields"
      :connection="destinationNode.connection"
      @update="$emit('update', $event)"
    />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import Condition from "./Condition";

export default defineComponent({
  emits: ["update"],

  components: {
    Condition,
  },

  props: {
    link: Object,
    workflow: Object,
    sourceNode: Object,
    sourceFields: Array,
    destinationNode: Object,
    destinationFields: Array,
  },

  data() {
    return {
      loading: true,
      functions: [],
    };
  },

  methods: {
    async loadFunctions() {
      this.loading = true;

      try {
        const { data: functions } = await this.$axios.get("/functions");
        this.functions = functions.data;
      } catch (error) {
        //
      }

      this.loading = false;
    },
  },

  created() {
    this.loadFunctions();
  },
});
</script>
