<template>
  <div class="font-medium bg-gray-100 p-2 px-3">
    <div class="d-between">
      <div class="text-truncate">{{ title }}</div>
      <VTooltip>
        <div class="text-truncate">{{ value }}</div>
        <template #popper>
          {{ value }}
        </template>
      </VTooltip>
    </div>
    <Textbox
      placeholder="Search Fields"
      class="mt-1"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
    />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import Textbox from "@/components/common/fields/Textbox";

export default defineComponent({
  emits: ["update:modelValue"],

  props: {
    title: String,
    modelValue: String,
    value: String,
  },

  components: {
    Textbox,
  },
});
</script>
