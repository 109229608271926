<template>
  <MenuItem v-slot="{ active }" @click="prompt = true">
    <a
      href="#"
      :class="[
        'group flex items-center px-4 py-2 text-sm ',
        active ? 'bg-gray-100 text-gray-800' : 'text-gray-700',
      ]"
    >
      <SwitchHorizontalIcon class="mr-3 h-5 w-5" aria-hidden="true" />
      Switch
    </a>
  </MenuItem>

  <Modal
    size="lg"
    :open="prompt"
    title="Switch Type"
    action="Switch"
    :loading="updating"
    @submit="handleUpdate"
    @close="prompt = false"
  >
    <p>Do you want to change the type from {{ node.type }} {{ switchTo }}?</p>
  </Modal>
</template>

<script>
import { defineComponent, computed, ref, toRefs } from "vue";
import { SwitchHorizontalIcon } from "@heroicons/vue/solid";
import { MenuItem } from "@headlessui/vue";
import Modal from "@/components/common/Modal";
import { NodeType } from "../flow";
import { useUpdate } from "../use/node";

export default defineComponent({
  components: {
    Modal,
    MenuItem,
    SwitchHorizontalIcon,
  },

  props: {
    node: Object,
  },

  setup(props) {
    const prompt = ref(false);
    const { node } = toRefs(props);
    const { update, updating } = useUpdate(node);

    const switchTo = computed(() => {
      return node.value.type === NodeType.Fetch
        ? NodeType.Fulfill
        : NodeType.Fetch;
    });

    async function handleUpdate() {
      await update({ type: switchTo.value });
      prompt.value = false;
    }

    return {
      prompt,
      updating,
      switchTo,
      handleUpdate,
    };
  },
});
</script>
