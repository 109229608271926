<template>
  <Form :value="formValues" :schema="schema" @submit="handleSubmit">
    <template #default="{ submit, submitting, ...formParams }">
      <form class="border p-3 rounded mt-3" @submit.prevent="submit">
        <div class="flex text-gray-900">
          <h2 class="flex flex-1 font-medium items-center leading-4 text-lg">
            <slot>Adapter</slot>
          </h2>

          <a href="#" @click.prevent="expanded = !expanded">
            <ChevronUpIcon v-if="isExpanded" class="h-5 w-5 fill-current" />
            <ChevronDownIcon v-else class="h-5 w-5 fill-current" />
          </a>
        </div>

        <template v-if="isExpanded">
          {{ name }}
          <Field
            :request-payload="createRequestPayload"
            v-for="field in fields"
            v-bind="formParams"
            :key="field._id"
            :prefix="prefix"
            :field="field"
            class="mt-3"
          />

          <div class="mt-3 flex justify-end">
            <Button
              type="submit"
              status="primary"
              :loading="submitting"
              :disabled="submitting"
            >
              Save
            </Button>
          </div>
        </template>
      </form>
    </template>
  </Form>
</template>

<script>
import { defineComponent } from "vue";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/vue/solid";
import form from "@/mixins/form";
import fields from "@/mixins/fields";
import Field from "@/components/common/Field";

export default defineComponent({
  emits: ["submit"],
  mixins: [form, fields],

  components: {
    Field,
    ChevronUpIcon,
    ChevronDownIcon,
  },

  props: {
    name: String,
    open: Boolean,
    fields: Array,
    values: Object,
    typeId: String,
    prefix: String,
    adapterId: String,
  },

  computed: {
    isExpanded() {
      return this.open && this.expanded;
    },
    schema() {
      return this.createSchema(this.fields, this.prefix);
    },
    formValues() {
      return this.createFieldValues(this.fields, this.values, this.prefix);
    },
  },

  data() {
    return {
      expanded: this.open,
    };
  },

  methods: {
    handleSubmit(event) {
      this.$emit("submit", event);
    },
    createRequestPayload(values) {
      return {
        name: this.name,
        type_id: this.typeId,
        adapter_id: this.adapterId,
        values: {
          ...this.values,
          ...values,
        },
      };
    },
  },

  watch: {
    open(value) {
      if (value) {
        this.expanded = true;
      }
    },
  },
});
</script>
