<template>
  <Actions>
    <SwitchType v-if="switchale" :node="node" />
    <Configure :node="node" ref="configure" />
    <Disconnect v-if="destinationLink" :link="destinationLink" />
    <Delete v-if="delectable" :node="node" />
  </Actions>
</template>

<script>
import { defineComponent, toRefs, computed } from "vue";
import { NodeType } from "../flow";
import { useDisconnect } from "../use/node";
import Actions from "./Actions";
import Configure from "./Configure";
import Delete from "./Delete";
import Disconnect from "./Disconnect";
import SwitchType from "./SwitchType";

export default defineComponent({
  components: {
    Actions,
    Configure,
    Delete,
    Disconnect,
    SwitchType,
  },

  props: {
    node: Object,
  },

  setup(props) {
    const { node } = toRefs(props);
    const { destinationLink } = useDisconnect(node);

    const actions = computed(() => {
      return {
        switchale: node.value.type !== NodeType.Listen,
        delectable: node.value.type !== NodeType.Listen,
      };
    });

    return {
      ...actions.value,
      destinationLink,
    };
  },
});
</script>
