import { string, number } from "yup";

export function getFieldName(field: any, prefix = "") {
  return prefix ? `${prefix}_${field.name}` : field.name;
}

export function validator(field: any, otherFields: any[] = []) {
  let validation: any;

  switch (field.component) {
    case "Number":
      validation = number();
      break;
    case "Dropdown":
      validation = string();
      break;
    default:
      validation = string();
      break;
  }

  if (field.validation) {
    field.validation.forEach((rule: string) => {
      const [schema, params] = rule.split(":");
      const [exceptField, value] = (params ?? "").split(",");

      switch (schema) {
        case "required":
          validation = validation.required();
          return;
        case "required_if":
          validation = validation.when(exceptField, {
            is: value,
            then: string().nullable().required().label(field.label),
          });
          return;
      }
    });
  }

  return validation.nullable().label(field.label);
}
