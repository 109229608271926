<template>
  <div
    class="
      bg-gray-100
      cursor-pointer
      flex
      font-medium
      hover:bg-primary-lighter
      items-center
      justify-between
      p-2
      px-3
    "
    @click="$emit('click')"
  >
    <span> {{ title }} </span>
    <div>
      <ChevronUpIcon class="w-6 h-6" v-if="active" />
      <ChevronDownIcon class="w-6 h-6" v-else />
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/vue/outline";

export default defineComponent({
  emits: ["click"],

  components: {
    ChevronUpIcon,
    ChevronDownIcon,
  },

  props: {
    active: Boolean,
    title: String,
  },
});
</script>
