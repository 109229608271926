<template>
  <a
    href="#"
    :key="field.name"
    :id="`source-item-${field.name}`"
    class="flex p-2 px-3 mt-1 relative rounded border-2"
    :class="[
      selected
        ? 'border-primary bg-blue-50 text-primary'
        : links.length
        ? 'border-primary bg-primary text-white'
        : 'border-primary bg-secondary-lighter text-gray-600',
    ]"
    @click.prevent="handleClick"
  >
    <div class="unselectable">
      {{ field.name }}
    </div>

    <div class="-right-2 absolute rounded-full fixed-top">
      <div
        class="w-5 h-5 rounded-full border-2"
        :class="[
          selected
            ? 'border-primary bg-primary text-primary'
            : links.length
            ? 'border-primary bg-yellow-300 text-green-600'
            : 'border-primary bg-secondary-lighter text-gray-600',
        ]"
      />
    </div>
  </a>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  emits: ["click"],

  props: {
    links: Array,
    field: Object,
    selected: Boolean,
  },

  methods: {
    handleClick() {
      const element = document.getElementById(`source-item-${this.field.name}`);
      this.$emit("click", { height: element.height });
    },
  },
});
</script>

<style scoped>
.fixed-top {
  top: calc(50% - 10px);
}
</style>
