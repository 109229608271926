<template>
  <div>
    <label :for="name" class="inline-flex items-center">
      <input
        type="radio"
        class="field-inline"
        :id="name"
        :name="name"
        :value="value"
        :checked="checked"
        :disabled="disabled"
        :required="required"
        :placeholder="placeholder"
        @blur="$emit('blur', $event)"
        @input="$emit('input', $event)"
        @focus="$emit('focus', $event)"
      />

      <slot>
        <span class="ml-2">{{ label }}</span>
      </slot>
    </label>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  emits: ["input", "blur", "focus"],

  props: {
    name: String,
    label: String,
    value: String,
    error: String,
    checked: Boolean,
    disabled: Boolean,
    required: Boolean,
    placeholder: String,
  },
});
</script>
