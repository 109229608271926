<template>
  <li
    class="
      p-2
      px-3
      mt-1
      relative
      bg-secondary-lighter
      hover:bg-primary-lighter
      text-gray-600
      hover:border-primary
    "
    @click="$emit('click')"
  >
    {{ text }}
  </li>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  emits: ["click"],

  props: {
    text: String,
  },
});
</script>
