<template>
  <div v-if="loading">loading...</div>
  <div v-else class="flex-1 flex flex-col">
    <Transformer
      v-if="activeLink"
      :link="activeLink"
      :workflow="workflow"
      @close="activeLinkId = null"
      @update="handleLinkUpdate"
    />

    <div v-else class="py-3 px-4 sm:px-6 lg:px-8">
      <FlowChart @links:select="handleLinkSelect" />
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, inject, provide, computed, toRefs } from "vue";
import FlowChart from "@/components/workflows/flow/Chart";
import Transformer from "@/components/workflows/Transformer";

export default defineComponent({
  components: {
    FlowChart,
    Transformer,
  },

  props: {
    workflow: Object,
  },

  setup(props) {
    const links = ref([]);
    const nodes = ref([]);
    const loading = ref(true);
    const activeLinkId = ref(null);
    const { workflow } = toRefs(props);

    const $axios = inject("axios");

    const activeLink = computed(() => {
      return links.value.find((i) => i._id === activeLinkId.value);
    });

    function handleLinkSelect(link) {
      activeLinkId.value = link;
    }

    function handleLinkUpdate(values) {
      links.value = links.value.map((i) => {
        if (i._id === activeLinkId.value) {
          return { ...i, ...values };
        }

        return i;
      });
    }

    (async function () {
      loading.value = true;

      try {
        const [{ data: nodeItems }, { data: linkItems }] = await Promise.all([
          $axios.get(`/workflows/${workflow.value._id}/nodes`),
          $axios.get(`/workflows/${workflow.value._id}/links`),
        ]);

        nodes.value = nodeItems.data;
        links.value = linkItems.data;
      } catch (error) {
        //
      }

      loading.value = false;
    })();

    provide("nodes", nodes);
    provide("links", links);
    provide("workflowId", workflow.value._id);

    return {
      nodes,
      links,
      loading,
      activeLink,
      activeLinkId,
      handleLinkSelect,
      handleLinkUpdate,
    };
  },
});
</script>
