<template>
  <Field :name="name" :label="label" :error="error" :required="required">
    <Select
      :aria-label="label"
      :name="name"
      :value="value"
      :multiple="multiple"
      :clearable="clearable"
      :options="mapOptions"
      :disabled="disabled || loading"
      :text-attribute="textAttribute"
      :hide-search-box="hideSearchBox"
      :value-attribute="valueAttribute"
      :normalized-options="normalizedOptions"
      :fetch-options="fetchable ? fetch : undefined"
      :placeholder="loading ? 'Loading...' : undefined"
      class="mt-1"
      :classes="{
        selectButton: [
          disabled
            ? 'border-secondary-light bg-secondary text-secondary-dark'
            : error
            ? 'focus:ring-danger focus:border-danger border-danger-light'
            : 'focus:ring-brand-500 focus:border-brand-500 border-secondary-light',
        ],
      }"
      @focus="handleFocus"
      @input="handleInput"
      @blur="$emit('blur', $event)"
    />
  </Field>
</template>

<script>
import { defineComponent } from "vue";
import Field from "@/components/common/fields/Field";
import Select from "@/components/common/Select";

export default defineComponent({
  components: {
    Field,
    Select,
  },

  props: {
    name: String,
    options: [Array, Object],
    normalizedOptions: [Array, Object],
    value: [Number, String],
    error: String,
    label: String,
    subLabel: String,
    required: Boolean,
    disabled: Boolean,
    autofocus: Boolean,
    multiple: Boolean,
    clearable: Boolean,
    hideSearchBox: Boolean,
    request: Object,
    createRequest: Function,
    textAttribute: String,
    valueAttribute: String,
    queryable: {
      type: Boolean,
      default: false,
    },
    queryParam: {
      type: String,
      default: "query",
    },
  },

  computed: {
    mapOptions() {
      return this.options ? this.options : this.nativeOptions;
    },
    fetchable() {
      return this.queryable && this.request;
    },
  },

  data() {
    return {
      loading: false,
      nativeOptions: [],
    };
  },

  methods: {
    async fetch(query) {
      let results = [];
      this.loading = true;

      if (this.request) {
        try {
          const { data: items } = await this.$axios.request({
            url: this.request.url,
            data: this.request.data,
            method: this.request.method ?? "GET",
            params: { ...this.request.params, [this.queryParam]: query },
          });

          results = items.data;
        } catch (error) {
          console.log("error", error);
          //
        }
      }

      this.loading = false;
      return { results };
    },

    async loadOptions() {
      if (!this.nativeOptions.length) {
        const { results } = await this.fetch();
        this.nativeOptions = results;
      }
    },

    handleFocus($event) {
      this.$emit("focus", $event);

      if (this.request && !this.queryable) {
        this.loadOptions();
      }
    },
    handleInput($event) {
      this.$emit("input", $event, this.name);
    },
  },

  created() {
    if (!this.request && !this.queryable) {
      this.loadOptions();
    }

    if (!this.queryable && this.request && this.value) {
      this.loadOptions();
    }
  },
});
</script>
