<template>
  <div>
    <div v-if="tokens.length">
      <ul>
        <li
          v-for="token in tokens"
          :key="token._id"
          class="
            flex
            items-center
            cursor-pointer
            rounded
            mb-3
            border-2
            p-3
            hover:bg-blue-100
            flex-1
            justify-between
          "
          @click="$emit('input', token._id, name)"
          :class="[
            value === token._id
              ? 'border-primary text-primary bg-blue-50'
              : 'bg-gray-50',
          ]"
        >
          <div>
            {{ token.label }} created at
            <Timestamp :value="token.created_at" />
          </div>

          <div>
            <CheckedCircleIcon v-if="value === token._id" class="w-6 h-6" />
            <CheckCircleIcon v-else class="w-6 h-6" />
          </div>
        </li>
      </ul>
    </div>

    <Button
      :loading="redirecting"
      class="flex items-center w-full"
      @click="handleClick"
    >
      <div class="flex-1 flex items-center">
        <img :src="filename" :alt="label" class="w-8" />
        <span class="ml-3">Add {{ label }}</span>
      </div>
      <PlusIcon class="w-4 h-4" />
    </Button>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { PlusIcon, CheckCircleIcon } from "@heroicons/vue/outline";
import { CheckCircleIcon as CheckedCircleIcon } from "@heroicons/vue/solid";
import Button from "@/components/common/Button";
import Timestamp from "@/components/common/Timestamp";
import icons from "@/assets/icons.svg";

export default defineComponent({
  emits: ["input", "blur", "focus"],

  computed: {
    filename() {
      try {
        return `${icons}#${this.icon}`;
      } catch (error) {
        return null;
      }
    },
  },

  components: {
    Button,
    PlusIcon,
    Timestamp,
    CheckCircleIcon,
    CheckedCircleIcon,
  },

  props: {
    name: String,
    icon: String,
    hint: String,
    label: String,
    value: String,
    error: String,
    request: Object,
    service: String,
    required: Boolean,
    disabled: Boolean,
    createRequest: Function,
  },

  data() {
    return {
      tokens: [],
      loading: true,
      redirecting: false,
    };
  },

  methods: {
    async load() {
      this.loading = true;
      try {
        const { data: tokens } = await this.$axios.get(
          `/service-tokens/${this.service}`
        );

        this.tokens = tokens.data;
      } catch (error) {
        //
      }
      this.loading = false;
    },
    async handleClick() {
      this.redirecting = true;

      try {
        const { data: token } = await this.$axios.post(
          `/service-tokens/${this.service}/authorize`,
          {
            refer: window.location.href,
          }
        );
        window.location.replace(token.data.url);
      } catch (error) {
        //
      }

      this.redirecting = false;
    },
  },

  created() {
    this.load();
  },
});
</script>
