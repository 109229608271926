import { ref, inject } from "vue";

export function useRemove(link?: any) {
  const removing = ref<boolean>(false);

  const links = inject<any>("links");
  const axios = inject<any>("axios");
  const workflowId = inject<string>("workflowId");

  async function removeById(linkId: string) {
    try {
      await axios.delete(`/workflows/${workflowId}/links/${linkId}`);
      links.value = links.value.filter((i: any) => i._id !== linkId);
    } catch (error) {
      console.log(error);
    }
  }

  async function remove() {
    removing.value = true;

    try {
      await removeById(link.value._id);
    } catch (error) {
      console.log(error);
    }

    removing.value = false;
  }

  return {
    remove,
    removing,
    removeById,
  };
}
