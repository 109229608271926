<template>
  <Modal
    open
    action="Config"
    :title="`Configure ${field.name}`"
    @close="handleClose"
    @submit="handleConfigure"
  >
    <Form
      ref="form"
      :schema="schema"
      :value="formValues"
      @submit="handleSubmit"
    >
      <template #default="{ submit, values, input, blue, setValue }">
        <form @submit.prevent="submit">
          <div class="grid grid-cols-2 gap-2">
            <Dropdown
              label="Lookup Object"
              name="object"
              text-attribute="name"
              value-attribute="name"
              :options="objects"
              :value="values.object"
              @input="handleLookupInput(setValue, $event)"
            />
            <Dropdown
              label="Retrieve Field"
              name="retrieve_field"
              text-attribute="name"
              value-attribute="name"
              :options="dataSets"
              :value="values.retrieve_field"
              v-on="{ input, blue }"
            />
          </div>

          <div class="mt-6">
            <h4>Apply Conditions & Values</h4>
            <div class="grid grid-cols-2 gap-2 mt-3">
              <div class="flex justify-between">
                <Dropdown
                  class="flex-1"
                  label="Condition Field(s)"
                  text-attribute="name"
                  value-attribute="name"
                  :options="dataSets"
                  :value="conditionField"
                  @input="conditionField = $event"
                />

                <Button
                  class="self-end ml-1"
                  @click="handleConditionAdd(values, setValue)"
                >
                  Add
                </Button>
              </div>

              <div class="flex justify-between">
                <Dropdown
                  class="flex-1"
                  name="subject"
                  label="Subject(s)"
                  :options="fields"
                  text-attribute="name"
                  value-attribute="name"
                  :value="conditionSubject"
                  @input="conditionSubject = $event"
                />

                <Button
                  class="self-end ml-1"
                  @click="handleSubjectAdd(values, setValue)"
                >
                  Add
                </Button>
              </div>
            </div>
          </div>

          <div class="mt-6">
            <h4>Condition(s) based on operator (and / or)</h4>
            <div class="flex mt-3">
              <Radio
                label="AND"
                value="AND"
                name="operator"
                :checked="values.operator === 'AND'"
                v-on="{ input, blue }"
              />
              <Radio
                label="OR"
                value="OR"
                class="ml-2"
                name="operator"
                :checked="values.operator === 'OR'"
                v-on="{ input, blue }"
              />
            </div>
          </div>

          <div class="bg-blue-50 border mt-6 p-3 rounded">
            <div
              :key="index"
              class="flex"
              v-for="(condition, index) in values.conditions"
            >
              <Dropdown
                class="mt-3 flex-1 mr-1"
                name="Operator"
                label="Operator"
                :options="operators"
                :value="condition.operator"
                @input="handleConditionOperator(index, values, $event, setValue)"
              />

              <Textbox
                class="mt-3 flex-1"
                :value="condition.value"
                :label="condition.field"
                :name="`conditions[${index}].value`"
                v-on="{ input, blue }"
                @focus="handleLookupFocus(index)"
              />

              <Button
                status="danger"
                class="self-end ml-1"
                @click="handleConditionRemove(index, values, setValue)"
              >
                Remove
              </Button>
            </div>
          </div>

          <div class="mt-6">
            <div class="flex mt-3">
              <Checkbox
                :value="true"
                name="nullable"
                label="Apply Null for empty lookup returns"
                :checked="!!values.nullable"
                v-on="{ input, blue }"
              />
            </div>
          </div>
        </form>
      </template>
    </Form>
  </Modal>
</template>

<script>
import { defineComponent } from "vue";
import { object } from "yup";
import Modal from "@/components/common/Modal";
import Button from "@/components/common/Button";
import form from "@/mixins/form";

export default defineComponent({
  mixins: [form],
  emits: ["close", "update"],

  components: {
    Modal,
    Button,
  },

  props: {
    field: Object,
    transform: Object,
    connection: Object,
    links: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    fields() {
      return this.links.map((i) => i.source);
    },
    formValues() {
      return (
        this.transform?.lookup ?? {
          object: null,
          retrieve_field: null,
          nullable: true,
          operator: "AND",
          conditions: [],
        }
      );
    },
  },

  data() {
    return {
      operators: ["=", "!=", "like"], 
      objects: [],
      dataSets: [],
      loading: true,
      conditionField: null,
      conditionSubject: null,
      lookupFocusIndex: null,
      schema: object().shape({}),
    };
  },

  methods: {
    async loadObjects() {
      this.loading = true;
      try {
        const { data: objects } = await this.$axios.post("brokers/objects", {
          connection_id: this.connection._id,
        });

        this.objects = objects.data;
      } catch (error) {
        //
      }
    },
    async loadDataSets(table) {
      this.loading = true;
      try {
        const { data: dataSets } = await this.$axios.post("brokers/datasets", {
          connection_id: this.connection._id,
          values: {
            model_type: "0",
            value: table,
          },
        });

        this.dataSets = dataSets.data[0].fields;
      } catch (error) {
        //
      }
    },
    handleClose() {
      this.$emit("close");
    },
    handleConfigure() {
      this.$refs.form.submit();
    },
    handleLookupFocus(index) {
      this.lookupFocusIndex = index;
    },
    handleLookupInput(setValue, $event) {
      if ($event) {
        this.loadDataSets($event);
      } else {
        this.dataSets = [];
      }

      setValue("object", $event);
      setValue("retrieve_field", null);
    },
    handleConditionOperator(index, { conditions }, val, setValue) {
       setValue(
        "conditions",
        conditions.map((condition, inx) =>
          inx === index
            ? {
                ...condition,
                operator: val,
              }
            : condition
        )
      );

    },
    handleConditionAdd({ conditions }, setValue) {
      setValue("conditions", [
        ...conditions,
        { field: this.conditionField, value: "", operator: ""},
      ]);
    },
    handleSubjectAdd({ conditions }, setValue) {
      setValue(
        "conditions",
        conditions.map((condition, index) =>
          index === this.lookupFocusIndex
            ? {
                ...condition,
                value: `[:[raw->${this.conditionSubject}]:]`,
              }
            : condition
        )
      );
    },
    handleConditionRemove(index, { conditions }, setValue) {
      setValue(
        "conditions",
        conditions.filter((_, idx) => idx !== index)
      );
    },
    handleSubmit({ values }) {
      this.$emit("update", { lookup: values });
    },
  },

  created() {
    this.loadObjects();
    if (this.transform?.lookup?.object) {
      this.loadDataSets(this.transform.lookup.object);
    }
  },
});
</script>
