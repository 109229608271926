<template>
  <Button :loading="creating" center v-tooltip="'Create Group'" @click="handle">
    <CubeIcon class="w-5 h-5" v-if="!creating" />
  </Button>
</template>

<script>
import { defineComponent, ref, inject } from "vue";
import { CubeIcon } from "@heroicons/vue/outline";
import Button from "@/components/common/Button";
import { GROUP_WIDTH, GROUP_HEIGHT } from "../flow";

export default defineComponent({
  components: {
    Button,
    CubeIcon,
  },

  setup() {
    const creating = ref(false);

    const nodes = inject("nodes");
    const axios = inject("axios");
    const workflowId = inject("workflowId");

    async function handle(connection) {
      creating.value = true;

      try {
        const { data: item } = await axios.post(
          `/workflows/${workflowId}/nodes`,
          {
            x: GROUP_WIDTH * 2,
            y: GROUP_HEIGHT * 2,
            width: GROUP_WIDTH,
            height: GROUP_HEIGHT,
            type: "Group",
            status: "Active",
            connection_id: connection._id,
          }
        );

        nodes.value = [...nodes.value, item.data];
      } catch (error) {
        //
      }

      creating.value = false;
    }

    return {
      handle,
      creating,
    };
  },
});
</script>
