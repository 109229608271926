<template>
  <svg class="absolute h-full w-full stroke-current text-primary">
    <path
      v-for="link in paths"
      :key="link.id"
      :d="link.path"
      fill="none"
      stroke-width="1.5"
      cursor="pointer"
      :stroke="
        active &&
        link.source === active.source &&
        link.destination === active.destination
          ? 'red'
          : undefined
      "
      @click="$emit('click', link)"
    />
  </svg>
</template>

<script>
import * as d3 from "d3";
import { defineComponent, ref, watch, onMounted } from "vue";
import debounce from "lodash/debounce";

export default defineComponent({
  emits: ["click"],

  props: {
    items: Array,
    active: Object,
  },

  setup(props) {
    const paths = ref([]);

    function updatePaths(items) {
      paths.value = items.map((link) => {
        const sourceElement = document.getElementById(
          `source-item-${link.source}`
        );

        const destinationElement = document.getElementById(
          `destination-item-${link.destination}`
        );

        if (sourceElement && destinationElement) {
          link.path = d3.linkHorizontal()({
            source: [
              sourceElement.offsetWidth,
              sourceElement.offsetTop + sourceElement.offsetHeight / 2,
            ],
            target: [
              destinationElement.offsetLeft,
              destinationElement.offsetTop +
                destinationElement.offsetHeight / 2,
            ],
          });
        } else {
          link.path = null;
        }

        return link;
      });
    }

    onMounted(() => {
      setTimeout(() => {
        updatePaths(props.items);
      });
    });

    watch(() => props.items, debounce(updatePaths));

    return {
      paths,
    };
  },
});
</script>
