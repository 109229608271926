<template>
  <Field :name="name" :label="label" :error="error" :required="required">
    <Ace
      class="field h-64"
      :name="name"
      :lang="lang"
      :theme="theme"
      :value="value || ''"
      :disabled="disabled"
      :class="{ 'mt-1': label }"
      :placeholder="placeholder"
      @blur="$emit('blur', $event)"
      @input="handleInput"
      @focus="$emit('focus', $event)"
    />

    <template #footer v-if="request">
      <div class="mt-2 flex justify-end">
        <Button type="button" @click="handleRequest"> Validate & Save </Button>
      </div>
    </template>
  </Field>
</template>

<script>
import { defineComponent } from "vue";
import Ace from "@/components/common/Ace";
import Button from "@/components/common/Button";
import Field from "@/components/common/fields/Field";

export default defineComponent({
  emits: ["input", "blur", "focus"],

  components: {
    Ace,
    Button,
    Field,
  },

  props: {
    name: String,
    label: String,
    value: [Number, String],
    error: String,
    disabled: Boolean,
    required: Boolean,
    placeholder: String,
    lang: String,
    theme: String,
    request: Object,
    createRequest: Function,
  },

  data() {
    return {
      data: "",
      loading: false,
    };
  },

  methods: {
    handleInput(value) {
      if (this.request) {
        this.data = value;
      } else {
        this.$emit("input", value, this.name);
      }
    },
    async handleRequest() {
      try {
        await this.$axios.request({
          url: this.request.url,
          method: this.request.method ?? "GET",
          data: { ...this.request.data, [this.name]: this.data },
        });

        this.$emit("input", this.data, this.name);
      } catch (error) {
        //
      }
    },
  },
});
</script>
