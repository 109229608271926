<template>
  <Actions>
    <Disconnect v-if="destinationLink" :link="destinationLink" />
    <Delete :node="group" />
  </Actions>
</template>

<script>
import { defineComponent, toRefs } from "vue";
import { useDisconnect } from "../use/node";
import Actions from "./Actions";
import Delete from "./Delete";
import Disconnect from "./Disconnect";

export default defineComponent({
  components: {
    Actions,
    Delete,
    Disconnect,
  },

  props: {
    group: Object,
  },

  setup(props) {
    const { group } = toRefs(props);
    const { destinationLink } = useDisconnect(group);

    return {
      destinationLink,
    };
  },
});
</script>
