<template>
  <Menu
    as="div"
    class="flex justify-end items-center -mr-2 -mt-3 -right-1.5 absolute"
  >
    <MenuButton
      class="
        w-8
        h-8
        shadow
        bg-white
        inline-flex
        items-center
        justify-center
        text-gray-400
        rounded-full
        hover:text-secondary-dark
        focus:outline-none
        focus:ring-2
        focus:ring-offset-2
        focus:ring-purple-500
      "
    >
      <span class="sr-only">Open options</span>
      <DotsVerticalIcon class="w-5 h-5" aria-hidden="true" />
    </MenuButton>
    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <MenuItems
        :unmount="false"
        class="
          mx-3
          origin-top-right
          absolute
          right-7
          top-0
          w-48
          mt-1
          rounded-md
          shadow-lg
          z-20
          bg-white
          ring-1 ring-black ring-opacity-5
          divide-y divide-gray-200
          focus:outline-none
        "
      >
        <slot />
      </MenuItems>
    </transition>
  </Menu>
</template>

<script>
import { defineComponent } from "vue";
import { Menu, MenuButton, MenuItems } from "@headlessui/vue";
import { DotsVerticalIcon } from "@heroicons/vue/solid";

export default defineComponent({
  components: {
    Menu,
    MenuButton,
    MenuItems,
    DotsVerticalIcon,
  },
});
</script>
