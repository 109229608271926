
import { defineComponent } from "vue";
import CreateNode from "./CreateNode.vue";
import CreateGroup from "./CreateGroup.vue";

export default defineComponent({
  components: {
    CreateNode,
    CreateGroup,
  },

  setup() {
    //
  },
});
