<template>
  <div
    class="bg-white flex flex-1 rounded-md border-2 overflow-hidden"
    :class="[valid ? 'border-pink-400' : 'border-red-600']"
  >
    <div
      class="w-12 flex justify-center items-center px-2"
      :class="[valid ? 'bg-pink-100' : 'bg-red-50']"
    >
      <img :src="icon" alt="" />
    </div>

    <div class="flex-1 px-3 py-2">
      <span class="absolute node-type bg-white border-2 border-pink-400 text-gray-900 text-xs mr-2 px-2.5 py-0.5 rounded dark:bg-white dark:text-gray-900">Fulfill</span>
      <div class="text-xs text-green-600">
        {{ node.connection?.adapter?.name }}
      </div>

      <template v-if="valid">
        <div class="font-semibold whitespace-nowrap">
          {{ node.connection?.name }}
        </div>

        <div class="flex items-center mt-1 text-xs">
          <CollectionIcon class="w-4 h-4" />
          <span class="whitespace-nowrap ml-1">
            {{ node.connection_values?.value }}
          </span>
        </div>
      </template>

      <template v-else>
        <Button class="mt-1 bg-red-50" @click="$emit('configure')">
          <CollectionIcon class="w-4 h-4" />
          <span class="ml-1">Configure</span>
        </Button>
      </template>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from "vue";
import { CollectionIcon } from "@heroicons/vue/outline";
import Button from "@/components/common/Button";
import { nodeIcon } from "../flow";

export default defineComponent({
  props: {
    node: Object,
  },

  components: {
    Button,
    CollectionIcon,
  },

  setup(props) {
    const icon = computed(() => {
      return nodeIcon(props.node);
    });

    const valid = computed(() => {
      return props.node.connection_values?.value;
    });

    return {
      icon,
      valid,
    };
  },
});
</script>
