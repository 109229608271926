<template>
  <div>
    <div class="flex justify-end">
      <div>
        <Button v-tooltip="'Straight Map Config'">
          <LightBulbIcon class="w-5 h-5" />
        </Button>
        <Button v-tooltip="'Names Map'" class="ml-1">
          <LightningBoltIcon class="w-5 h-5" />
        </Button>
        <AutoMap
          class="ml-1"
          :source-fields="sourceFields"
          :destination-fields="destinationFields"
          @map="handleAutoMap"
        />
        <Button v-tooltip="'Clear all maps'" class="ml-1" @click="handleLinkRemovedAll">
          <TrashIcon class="w-5 h-5 text-danger-dark hover:text-danger-darker"/>
        </Button>
        <slot />
      </div>
    </div>

    <div id="connection-graph" class="gap-1 grid grid-cols-3 relative mt-3">
      <BluePrint
        :active="activeLink"
        :items="visibleLinks"
        @click="handleLinkActive"
      />

      <div class="z-10">
        <Header
          :title="`${sourceNode.connection.name}`"
          :value="`${sourceNode.connection_values.value}`"
          v-model="sourceQuery"
        />
        <div class="flex flex-col mt-3" id="graph-source">
          <SourceItem
            v-for="(field, index) in visibleSources"
            :key="index"
            :field="field"
            :links="sourceLinks(field.name)"
            :selected="field.name === sourceItem?.name"
            @click="handleSourceClick(field, $event)"
          />
        </div>
      </div>

      <div>
        <!--  -->
      </div>

      <div class="z-10">
        <Header
          :title="`${destinationNode.connection.name}`"
          :value="`${destinationNode.connection_values.value}`"
          v-model="destinationQuery"
        />
        <div class="flex flex-col mt-3" id="graph-destination">
          <DestinationItem
            v-for="(field, index) in visibleDestinations"
            :key="index"
            :field="field"
            :transform="transform[field.name]"
            :links="destinationLinks(field.name)"
            :destination="destinationNode.connection"
            :selected="field.name === destinationItem?.name"
            @click="handleDestinationClick(field, $event)"
            @update="handleLinkUpdate(field, $event)"
            @remove="handleLinkRemoved(field, $event)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as d3 from "d3";
import { defineComponent } from "vue";
import { LightBulbIcon, LightningBoltIcon, TrashIcon } from "@heroicons/vue/outline";
import Button from "@/components/common/Button";
import AutoMap from "@/components/workflows/transform/graph/AutoMap";
import BluePrint from "@/components/workflows/transform/graph/BluePrint";
import Header from "@/components/workflows/transform/graph/Header";
import SourceItem from "@/components/workflows/transform/graph/SourceItem";
import DestinationItem from "@/components/workflows/transform/graph/DestinationItem";

export default defineComponent({
  emits: ["update"],

  components: {
    AutoMap,
    Button,
    TrashIcon,
    LightBulbIcon,
    LightningBoltIcon,
    Header,
    BluePrint,
    SourceItem,
    DestinationItem,
  },

  props: {
    link: Object,
    workflow: Object,
    sourceNode: Object,
    sourceFields: Array,
    destinationNode: Object,
    destinationFields: Array,
  },

  computed: {
    transform() {
      return this.link?.transform ?? {};
    },
    mappedLinks() {
      return Object.keys(this.transform).reduce((carry, destination) => {
        const item = this.transform[destination];

        if (!item.maps?.length) {
          return carry;
        }

        return carry.concat(
          item.maps.map((source) => ({ source, destination }))
        );
      }, []);
    },
    visibleSources() {
      return this.sourceFields.filter((field) => {
        return field.name
          .toLowerCase()
          .includes(this.sourceQuery.toLowerCase());
      });
    },
    visibleDestinations() {
      return this.destinationFields.filter((field) => {
        return field.name
          .toLowerCase()
          .includes(this.destinationQuery.toLowerCase());
      });
    },
    visibleLinks() {
      return this.mappedLinks.filter(
        (link) =>
          this.visibleSources.some((i) => i.name === link.source) &&
          this.visibleDestinations.some((i) => i.name === link.destination)
      );
    },
  },

  data() {
    return {
      sourceQuery: "",
      destinationQuery: "",

      sourceItem: null,
      destinationItem: null,

      graph: null,
      activeLink: null,
      autoMapping: false,
      conditionsVisible: false,
    };
  },

  methods: {
    async handleUpdate() {
      try {
        const { data: mappings } = await this.$axios.post("mappings", {
          source: this.sourceFields.map((i) => i.name),
          destination: this.destinationFields.map((i) => i.name),
        });

        mappings.data.forEach((mapping) => {
          const source = this.sourceFields.find(
            (i) => i.name === mapping.source
          );

          const destination = this.destinationFields.find(
            (i) => i.name === mapping.destination
          );

          if (source && destination) {
            this.createConnection(source, destination);
          }
        });
      } catch (error) {
        //
      }

      this.autoMapping = false;
    },
    async createConnection(source, destination) {
      let link = this.transform[destination.name];
      if (link && link.maps?.indexOf(source.name) >= 0) {
        return;
      }

      const transform = {
        ...this.transform,
        [destination.name]: {
          ...link,
          maps: [...(link?.maps ?? []), source.name],
        },
      };

      try {
        this.$emit("update", { transform });
        await this.$axios.put(
          `/workflows/${this.workflow._id}/links/${this.link._id}`,
          {
            transform,
          }
        );
      } catch (error) {
        //
      }
    },
    async handleLinkUpdate(field, values) {
      this.sourceItem = null;
      this.destinationItem = null;

      const transform = {
        ...this.transform,
        [field.name]: {
          ...this.transform[field.name],
          ...values,
        },
      };

      try {
        this.$emit("update", { transform });
        await this.$axios.put(
          `/workflows/${this.workflow._id}/links/${this.link._id}`,
          {
            transform,
          }
        );
      } catch (error) {
        //
      }
    },
    handleLinkActive(link) {
      if (
        this.activeLink &&
        this.activeLink.source === link.source &&
        this.activeLink.destination === link.destination
      ) {
        this.activeLink = null;
        return;
      }

      this.activeLink = link;
    },
    async handleLinkRemoved(link) {
      const links = this.transform;
      let transform;
      let field = link?.field ?? (link?.name ?? false);

      if (field !=  false && (links?.[field] ?? false)) {
        delete links[field]
        transform = links;
      } else {
        transform = links;
      }

      try {
        this.$emit("update", { transform });
        await this.$axios.put(
          `/workflows/${this.workflow._id}/links/${this.link._id}`,
          {
            transform,
          }
        );
        this.activeLink = null;
      } catch (error) {
        //
      }
    },
    async handleLinkRemovedAll() {
      try {
        let text = "Are you sure want to remove all the link?";
        if (confirm(text) == true) {
          this.$emit("update", {transform: {}});
          await this.$axios.put(
            `/workflows/${this.workflow._id}/links/${this.link._id}`,
            {
              transform: {},
            }
          );
          this.activeLink = null;
        }
      } catch (error) {
        //
      }
    },
    async handleAutoMap(links) {
      const transform = {};

      links.forEach((link) => {
        transform[link.destination] = {
          maps: [link.source],
        };
      });

      try {
        this.$emit("update", { transform });
        await this.$axios.put(
          `/workflows/${this.workflow._id}/links/${this.link._id}`,
          {
            transform,
          }
        );
      } catch (error) {
        //
      }
    },
    renderGraph() {
      this.graph = d3.select("#connection-graph");
    },
    handleSourceClick(item) {
      this.activeLink = null;

      if (this.sourceItem && this.sourceItem.name === item.name) {
        this.sourceItem = null;
      } else if (!this.destinationItem) {
        this.sourceItem = item;
      } else {
        this.createConnection(item, this.destinationItem);
        this.destinationItem = null;
      }
    },
    handleDestinationClick(item) {
      this.activeLink = null;

      if (this.destinationItem && this.destinationItem.name === item.name) {
        this.destinationItem = null;
      } else if (!this.sourceItem) {
        this.destinationItem = item;
      } else {
        this.createConnection(this.sourceItem, item);
        this.sourceItem = null;
      }
    },
    destinationLinks(field) {
      return this.visibleLinks.filter((link) => link.destination === field);
    },
    sourceLinks(field) {
      return this.visibleLinks.filter((link) => link.source === field);
    },
    listenRemovePress(event) {
      const key = event.keyCode || event.charCode;

      if (this.activeLink && (key == 8 || key == 46)) {
        this.handleLinkRemoved(this.activeLink);
      }
    },
  },

  mounted() {
    window.addEventListener("keydown", this.listenRemovePress);
  },

  beforeUnmount() {
    window.removeEventListener("keydown", this.listenRemovePress);
  },
});
</script>
