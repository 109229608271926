<template>
  <div
    class="
      absolute
      bottom-0
      bg-blue-300
      p-1
      right-0
      -mr-2
      -mb-2
      cursor-crosshair
      resize-point
      text-white
    "
  >
    <ArrowsExpandIcon class="w-3 h-3 resize-point" />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { ArrowsExpandIcon } from "@heroicons/vue/outline";

export default defineComponent({
  components: {
    ArrowsExpandIcon,
  },

  props: {
    position: {
      type: String,
      default: "right",
    },
  },

  setup() {
    //
  },
});
</script>
