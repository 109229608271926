<template>
  <Field :name="name" :label="label" :error="error" :required="required">
    <Highlightable
      class="field"
      :name="name"
      :value="value"
      :disabled="disabled"
      :highlight="highlight"
      contenteditable="true"
      :class="{ 'mt-1': label }"
      :placeholder="placeholder"
      @blur="$emit('blur', name, $event)"
      @focus="$emit('focus', name, $event)"
      @input="$emit('input', $event, name)"
    />
  </Field>
</template>

<script>
import { defineComponent } from "vue";
import Field from "@/components/common/fields/Field";
import Highlightable from "@/components/common/Highlightable";

export default defineComponent({
  emits: ["input", "blur", "focus"],

  components: {
    Field,
    Highlightable,
  },

  props: {
    name: String,
    label: String,
    value: String,
    error: String,
    highlight: Array,
    required: Boolean,
    disabled: Boolean,
    placeholder: String,
  },
});
</script>
