<template>
  <div
    class="bg-white flex flex-1 rounded-md border-2 overflow-hidden"
    :class="[node.connection ? 'border-yellow-600' : 'border-red-600']"
  >
    <template v-if="node.connection">
      <div class="bg-yellow-50 w-12 flex justify-center items-center px-2">
        <img :src="icon" alt="" />
      </div>

      <div class="flex-1 px-3 py-2">
        <div class="text-xs text-yellow-600">
          {{ node.connection?.adapter?.name }}
        </div>

        <div class="font-semibold whitespace-nowrap">
          {{ node.connection?.name }}
        </div>

        <div class="flex items-center mt-1 text-xs">
          <LightningBoltIcon class="w-4 h-4" />
          <span class="whitespace-nowrap ml-1">
            {{ node.trigger_values?.action }}
          </span>
        </div>
      </div>
    </template>

    <template v-else>
      <div class="bg-red-50 w-full flex justify-center items-center px-2">
        <div class="flex-1 px-3 py-2">
          <div class="text-lx text-yellow-600">Start</div>
          <div class="text-xs text-indigo-600">Listener (optional)</div>
          <!-- <small>Listener (optional <a href="#" class="text-blue-600 hover:text-blue-800 visited:text-purple-600" @click="$emit('configure')">Configure</a>)</small> -->
          <Button class="px-4 text-xs start-btn-conf mt-1" @click="$emit('configure')">
            <LightningBoltIcon class="w-4 h-4" />
            <span class="ml-1 text-xs">Configure</span>
          </Button>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { defineComponent, computed } from "vue";
import { LightningBoltIcon } from "@heroicons/vue/outline";
import Button from "@/components/common/Button";
import { nodeIcon } from "../flow";

export default defineComponent({
  emits: ["configure"],

  props: {
    node: Object,
  },

  components: {
    Button,
    LightningBoltIcon,
  },

  setup(props) {
    const icon = computed(() => {
      return nodeIcon(props.node);
    });

    return {
      icon,
    };
  },
});
</script>



<style scoped>
  .start-btn-conf {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
</style>