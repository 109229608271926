<template>
  <div class="lg:border-t lg:border-b lg:border-gray-200">
    <nav class="mx-auto" aria-label="Progress">
      <ol
        class="
          rounded-md
          overflow-hidden
          lg:flex lg:border-l lg:border-r lg:border-gray-200 lg:rounded-none
        "
      >
        <li
          :key="step.id"
          v-for="(step, stepIdx) in generatedSteps"
          class="relative overflow-hidden lg:flex-1"
        >
          <div
            :class="[
              stepIdx === 0 ? 'border-b-0 rounded-t-md' : '',
              stepIdx === steps.length - 1 ? 'border-t-0 rounded-b-md' : '',
              'border border-gray-200 overflow-hidden lg:border-0',
            ]"
          >
            <a
              v-if="step.status === 'complete'"
              :href="step.href"
              class="group"
              @click.prevent="$emit('change', step.id)"
            >
              <span
                class="
                  absolute
                  top-0
                  left-0
                  w-1
                  h-full
                  bg-transparent
                  group-hover:bg-gray-200
                  lg:w-full lg:h-1/2 lg:bottom-0 lg:top-auto
                "
                aria-hidden="true"
              />
              <span
                :class="[
                  stepIdx !== 0 ? 'lg:pl-9' : '',
                  'px-6 py-5 flex items-start text-sm font-medium',
                ]"
              >
                <span class="flex-shrink-0">
                  <span
                    class="
                      w-10
                      h-10
                      flex
                      items-center
                      justify-center
                      bg-primary
                      rounded-full
                    "
                  >
                    <CheckIcon class="w-6 h-6 text-white" aria-hidden="true" />
                  </span>
                </span>
                <span class="mt-0.5 ml-4 min-w-0 flex flex-col">
                  <span class="text-xs font-semibold tracking-wide uppercase">{{
                    step.name
                  }}</span>
                  <span class="text-sm font-medium text-secondary-dark">{{
                    step.description
                  }}</span>
                </span>
              </span>
            </a>
            <a
              v-else-if="step.status === 'current'"
              :href="step.href"
              aria-current="step"
              @click.prevent="$emit('change', step.id)"
            >
              <span
                class="
                  absolute
                  top-0
                  left-0
                  w-1
                  h-full
                  bg-primary
                  lg:w-full lg:h-1/2 lg:bottom-0 lg:top-auto
                "
                aria-hidden="true"
              />
              <span
                :class="[
                  stepIdx !== 0 ? 'lg:pl-9' : '',
                  'px-6 py-5 flex items-start text-sm font-medium',
                ]"
              >
                <span class="flex-shrink-0">
                  <span
                    class="
                      w-10
                      h-10
                      flex
                      items-center
                      justify-center
                      border-2 border-primary
                      rounded-full
                    "
                  >
                    <span class="border-primary">{{ step.id }}</span>
                  </span>
                </span>
                <span class="mt-0.5 ml-4 min-w-0 flex flex-col">
                  <span
                    class="
                      text-xs
                      font-semibold
                      border-primary
                      tracking-wide
                      uppercase
                    "
                    >{{ step.name }}</span
                  >
                  <span class="text-sm font-medium text-secondary-dark">{{
                    step.description
                  }}</span>
                </span>
              </span>
            </a>
            <a
              v-else
              :href="step.href"
              class="group"
              @click.prevent="handleGroupClick(step)"
            >
              <span
                class="
                  absolute
                  top-0
                  left-0
                  w-1
                  h-full
                  bg-transparent
                  group-hover:bg-gray-200
                  lg:w-full lg:h-1/2 lg:bottom-0 lg:top-auto
                "
                aria-hidden="true"
              />
              <span
                :class="[
                  stepIdx !== 0 ? 'lg:pl-9' : '',
                  'px-6 py-5 flex items-start text-sm font-medium',
                ]"
              >
                <span class="flex-shrink-0">
                  <span
                    class="
                      w-10
                      h-10
                      flex
                      items-center
                      justify-center
                      border-2 border-secondary-light
                      rounded-full
                    "
                  >
                    <span class="text-secondary-dark">{{ step.id }}</span>
                  </span>
                </span>
                <span class="mt-0.5 ml-4 min-w-0 flex flex-col">
                  <span
                    class="
                      text-xs
                      font-semibold
                      text-secondary-dark
                      tracking-wide
                      uppercase
                    "
                    >{{ step.name }}</span
                  >
                  <span class="text-sm font-medium text-secondary-dark">{{
                    step.description
                  }}</span>
                </span>
              </span>
            </a>
            <template v-if="stepIdx !== 0">
              <!-- Separator -->
              <div
                class="hidden absolute top-0 left-0 w-3 inset-0 lg:block"
                aria-hidden="true"
              >
                <svg
                  class="h-full w-full text-gray-300"
                  viewBox="0 0 12 82"
                  fill="none"
                  preserveAspectRatio="none"
                >
                  <path
                    d="M0.5 0V31L10.5 41L0.5 51V82"
                    stroke="currentcolor"
                    vector-effect="non-scaling-stroke"
                  />
                </svg>
              </div>
            </template>
          </div>
        </li>
      </ol>
    </nav>
  </div>
</template>

<script>
import { CheckIcon } from "@heroicons/vue/solid";

export default {
  emits: ["change"],

  components: {
    CheckIcon,
  },

  props: {
    steps: Array,
    active: Number,
    visitUpcoming: Boolean,
  },

  computed: {
    generatedSteps() {
      return this.steps.map((step) => {
        if (step.id < this.active) {
          step.status = "complete";
        } else if (step.id === this.active) {
          step.status = "current";
        } else {
          step.status = "upcoming";
        }

        return step;
      });
    },
  },

  methods: {
    handleGroupClick(step) {
      if (this.visitUpcoming) {
        this.$emit("change", step.id);
      }
    },
  },
};
</script>
