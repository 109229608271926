import { defineComponent } from "vue";
import has from "lodash/has";
import set from "lodash/set";
import get from "lodash/get";
import { object } from "yup";
import { validator, getFieldName } from "@/lib/field";

export default defineComponent({
  methods: {
    createSchema(fields: any[], prefix = "") {
      return object().shape(
        fields.reduce((carry: any, field: any) => {
          const fieldName = getFieldName(field, prefix);
          const fieldValidator = validator(field, fields);

          if (fieldName.includes(".")) {
            const [objKey, fieldKey] = fieldName.split(".");
            const objSchema = object().shape({
              [fieldKey]: fieldValidator,
            });

            set(carry, objKey, objSchema);
          } else {
            set(carry, fieldName, fieldValidator);
          }

          return carry;
        }, {})
      );
    },
    createFieldValues(fields: any[], values: any = {}, prefix = "") {
      return fields.reduce((carry: any, field: any) => {
        const fieldName = getFieldName(field, prefix);

        const value = has(values, fieldName)
          ? get(values, fieldName)
          : field.default_value;

        set(carry, fieldName, value);

        return carry;
      }, {});
    },
  },
});
