<template>
  <div
    :style="{
      width: width ? px(width) : undefined,
      height: height ? px(height) : undefined,
    }"
  />
</template>

<script>
import { defineComponent } from "vue";
import ace from "brace";
import "brace/theme/solarized_dark";
import "brace/mode/sql";
import "brace/mode/javascript";
import "brace/mode/text";

export default defineComponent({
  emits: ["init", "input", "blur", "focus"],

  props: {
    lang: String,
    theme: String,
    width: Number,
    height: Number,
    value: String,
    options: Object,
  },
  data() {
    return {
      editor: null,
      contentBackup: "",
    };
  },
  methods: {
    px(n) {
      if (/^\d*$/.test(n)) {
        return n + "px";
      }
      return n;
    },
  },
  watch: {
    value(val) {
      if (this.contentBackup !== val) {
        this.editor.session.setValue(val, 1);
        this.contentBackup = val;
      }
    },
    theme(newTheme) {
      this.editor.setTheme("ace/theme/" + newTheme);
    },
    lang(newLang) {
      this.editor
        .getSession()
        .setMode(typeof newLang === "string" ? "ace/mode/" + newLang : newLang);
    },
    options(newOption) {
      this.editor.setOptions(newOption);
    },
    height() {
      this.$nextTick(() => {
        this.editor.resize();
      });
    },
    width() {
      this.$nextTick(() => {
        this.editor.resize();
      });
    },
  },
  beforeUnmount() {
    this.editor.destroy();
    this.editor.container.remove();
  },
  mounted() {
    const lang = this.lang || "text";
    const theme = this.theme || "solarized_dark";

    require("brace/ext/emmet");

    const editor = (this.editor = ace.edit(this.$el));
    editor.$blockScrolling = Infinity;

    this.$emit("init", editor);

    //editor.setOption("enableEmmet", true);
    editor
      .getSession()
      .setMode(typeof lang === "string" ? "ace/mode/" + lang : lang);

    editor.setTheme("ace/theme/" + theme);

    if (this.value) {
      editor.setValue(this.value, 1);
    }

    this.contentBackup = this.value;

    editor.on("change", () => {
      const content = editor.getValue();
      this.$emit("input", content);
      this.contentBackup = content;
    });

    editor.on("blur", (event) => {
      this.$emit("blur", event);
    });

    editor.on("focus", (event) => {
      this.$emit("focus", event);
    });

    if (this.options) {
      editor.setOptions(this.options);
    }
  },
});
</script>
