<template>
  <div>
    <a
      href="#"
      :key="field.name"
      :id="`destination-item-${field.name}`"
      class="flex p-2 px-3 mt-1 relative rounded flex-col bg-primary-lighter"
      :class="[
        selected
          ? 'border-primary text-primary'
          : links.length
          ? 'border-green-600 text-gray-600'
          : 'border-blue-50 text-gray-600',
      ]"
      @click.prevent="handleClick"
    >
      <div class="-left-3 absolute rounded-full linker fixed-top">
        <div
          class="w-5 h-5 rounded-full border-2"
          :class="[
            selected
              ? 'border-primary bg-primary text-primary'
              : links.length
              ? 'border-primary bg-yellow-300 text-gray-600'
              : 'border-primary bg-blue-50 text-gray-600',
          ]"
        />
      </div>

      <div class="flex justify-between items-center">
        <div class="unselectable">
          <div>{{ field.name }}</div>
        </div>

        <div class="flex flex-row">
          <PencilIcon
            v-tooltip="'Add static value'"
            class="w-5 h-5 hover:border-primary ml-2"
            @click.stop="promptEdit = true"
          />
          <CodeIcon
            v-tooltip="'Apply functions'"
            class="w-5 h-5 hover:border-primary ml-2"
            @click.stop="promptFunction = true"
          />
          <CogIcon
            v-tooltip="'Lookup'"
            class="w-5 h-5 hover:border-primary ml-2"
            @click.stop="promptLookup = true"
          />
          <TrashIcon
            v-tooltip="'Clear maps'"
            class="w-5 h-5 text-danger-dark hover:text-danger-darker ml-2"
            @click.stop="handleClear"
          />
        </div>
      </div>

      <div
        v-if="transform?.static || transform?.maps?.length"
        class="p-3 rounded mt-3 border-2"
        :class="[
          selected
            ? 'bg-primary border-primary text-white'
            : links.length
            ? 'bg-primary border-primary text-white'
            : 'bg-white border-primary-light',
        ]"
      >
        <div v-if="transform?.static" class="whitespace-nowrap truncate">
          <span class="font-semibold">Static Value:</span>
          {{ transform.static }}
        </div>
        <div v-if="transform.maps?.length" class="whitespace-nowrap truncate">
          <span class="font-semibold">Linked To:</span>
          {{ transform.maps.join(", ") }}
        </div>
      </div>
    </a>

    <StaticValue
      v-if="promptEdit"
      :field="field"
      :transform="transform"
      :connection="destination"
      @close="promptEdit = false"
      @update="handleUpdate"
    />

    <ApplyFunctions
      v-if="promptFunction"
      :field="field"
      :links="links"
      :transform="transform"
      :connection="destination"
      @close="promptFunction = false"
      @update="handleFunction"
    />

    <LookupField
      v-if="promptLookup"
      :field="field"
      :links="links"
      :transform="transform"
      :connection="destination"
      @close="promptLookup = false"
      @update="handleLookup"
    />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { CogIcon, CodeIcon } from "@heroicons/vue/outline";
import { TrashIcon, PencilIcon } from "@heroicons/vue/outline";
import ApplyFunctions from "./actions/ApplyFunctions";
import LookupField from "./actions/lookup/Index";
import StaticValue from "./actions/StaticValue";

export default defineComponent({
  emits: ["click", "update", "remove"],

  components: {
    CogIcon,
    CodeIcon,
    TrashIcon,
    PencilIcon,
    StaticValue,
    ApplyFunctions,
    LookupField,
  },

  props: {
    links: Array,
    field: Object,
    transform: Object,
    selected: Boolean,
    destination: Object,
  },

  data() {
    return {
      promptEdit: null,
      promptLookup: null,
      promptFunction: null,
    };
  },

  methods: {
    handleClear() {
      this.$emit("remove");
    },
    handleLookup(values) {
      this.$emit("update", values);
      this.promptLookup = false;
    },
    handleUpdate(values) {
      this.$emit("update", values);
      this.promptEdit = false;
    },
    handleFunction(values) {
      this.$emit("update", values);
      this.promptFunction = false;
    },
    handleClick() {
      this.$emit("click");
    },
  },
});
</script>

<style scoped>
.fixed-top {
  top: calc(50% - 10px);
}
</style>
