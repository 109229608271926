<template>
  <div>
    <Textbox @input="load" placeholder="Search connection types." />

    <div v-if="typesLoading" class="flex justify-center py-6">
      <Spinner class="w-10 h-10 text-gray-400" />
    </div>

    <ul class="divide-y divide-gray-200 mt-3" v-else>
      <ListItem
        v-for="type in types"
        :key="type._id"
        :item="type"
        :selected="type._id === selectedId"
        :loading="type._id === selectedId && typeLoading"
        @select="handleSelect(type)"
      />
    </ul>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
import { defineComponent } from "vue";
import Textbox from "@/components/common/fields/Textbox";
import Spinner from "@/components/common/Spinner";
import ListItem from "./ListItem";

export default defineComponent({
  emits: ["type", "adapter-type"],

  components: {
    Textbox,
    Spinner,
    ListItem,
  },

  props: {
    adapter: Object,
  },

  data() {
    return {
      types: [],
      selectedId: null,
      typeLoading: false,
      typesLoading: true,
    };
  },

  methods: {
    load: debounce(async function (event) {
      this.typesLoading = true;

      try {
        const { data: types } = await this.$axios.get(
          `/adapters/${this.adapter._id}/types`,
          {
            params: {
              "filters[name]": event?.target.value ?? "",
            },
          }
        );

        this.types = types.data;
      } catch (error) {
        console.log(error);
      }

      this.typesLoading = false;
    }, 300),

    async handleSelect(item) {
      this.selectedId = item._id;
      this.typeLoading = true;

      try {
        const [{ data: type }, { data: adapterType }] = await Promise.all([
          this.$axios.get(`/types/${this.selectedId}`),
          this.$axios.get(
            `/adapters/${this.adapter._id}/types/${this.selectedId}`
          ),
        ]);

        this.$emit("type", type.data);
        this.$emit("adapter-type", adapterType.data);
      } catch (error) {
        this.selectedId = null;
      }

      this.typeLoading = false;
    },
  },

  created() {
    this.load();
  },
});
</script>
