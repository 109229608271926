<template>
  <MenuItem v-slot="{ active }" @click="prompt = true">
    <a
      href="#"
      :class="[
        active ? 'bg-red-100 text-red-900' : 'text-red-700',
        'group flex items-center px-4 py-2 text-sm',
      ]"
    >
      <LinkIcon
        class="mr-3 h-5 w-5 group-hover:text-danger"
        aria-hidden="true"
      />
      Disconnect
    </a>
  </MenuItem>

  <Modal
    :open="prompt"
    status="danger"
    title="Delete Item"
    action="Delete"
    size="lg"
    :loading="removing"
    @submit="remove"
    @close="prompt = false"
  >
    <p>Do you want to disconnect?</p>
  </Modal>
</template>

<script>
import { defineComponent, ref, toRefs } from "vue";
import { LinkIcon } from "@heroicons/vue/solid";
import { MenuItem } from "@headlessui/vue";
import { useRemove } from "../use/link";
import Modal from "@/components/common/Modal";

export default defineComponent({
  components: {
    Modal,
    MenuItem,
    LinkIcon,
  },

  props: {
    link: Object,
  },

  setup(props) {
    const { link } = toRefs(props);

    const prompt = ref(false);
    const remove = useRemove(link);

    return {
      prompt,
      ...remove,
    };
  },
});
</script>
