import * as d3 from "d3";
import debounce from "lodash/debounce";
import { computed, ref, inject, onMounted, watch } from "vue";
import { nodeSize, nodeIcon, NodeType, into } from "../flow";
import { NODE_WIDTH, NODE_HEIGHT } from "../flow";
import { useRemove as useRemoveLink } from "./link";

export function useNode(node: any, boundary: any) {
  const update = useUpdate(node);
  const remove = useRemove(node);
  const drag = useDrag(node, boundary, update);

  const nodeStyle = computed(() => {
    const size = nodeSize(node.value);

    return {
      top: `${node.value.y}px`,
      left: `${node.value.x}px`,
      width: `${size.width}px`,
      height: `${size.height}px`,
    };
  });

  const icon = computed(() => {
    return nodeIcon(node.value);
  });

  return {
    icon,
    ...drag,
    ...remove,
    ...update,
    nodeStyle,
  };
}

export function useRemove(node: any) {
  const removing = ref<boolean>(false);

  const nodes = inject<any>("nodes");
  const axios = inject<any>("axios");
  const workflowId = inject<string>("workflowId");

  async function remove() {
    removing.value = true;

    try {
      await axios.delete(`/workflows/${workflowId}/nodes/${node.value._id}`);
      nodes.value = nodes.value.filter((i: any) => i._id !== node.value._id);
    } catch (error) {
      console.log(error);
    }

    removing.value = false;
  }

  return {
    remove,
    removing,
  };
}

export function useUpdate(node: any) {
  const updating = ref<boolean>(false);

  const nodes = inject<any>("nodes");
  const axios = inject<any>("axios");
  const workflowId = inject<any>("workflowId");

  async function updateBatch(values: any) {
    try {
      await axios.put(`/workflows/${workflowId}/batch/nodes`, values);
    } catch (error) {
      //
    }
  }

  async function updateById(nodeId: string, values: any) {
    try {
      await axios.put(`/workflows/${workflowId}/nodes/${nodeId}`, values);

      nodes.value = nodes.value.map((i: any) =>
        i._id == nodeId ? { ...i, ...values } : i
      );
    } catch (error) {
      //
    }
  }

  async function update(values: any) {
    updating.value = true;
    await updateById(node.value._id, values);
    updating.value = false;
  }

  return {
    update,
    updating,
    updateById,
    updateBatch,
  };
}

export function useDrag(
  node: any,
  boundary: any,
  { update, updateById, updateBatch }: any
) {
  const element = ref<any>();
  const nodes = inject<any>("nodes");
  const links = inject<any>("links");
  const changed = ref<boolean>(false);
  const resizable = ref<boolean>(false);
  const link = useRemoveLink();

  const nodeId = computed(() => {
    return `node-${node.value._id}`;
  });

  const disabled = computed(() => {
    return node.value.type === NodeType.Listen;
  });

  const makeSureUpdateChildren = debounce(() => {
    if (node.value.children && node.value.children.length) {
      const values = node.value.children.reduce((carry: any, i: string) => {
        const node = nodes.value.find((j: any) => j._id === i);
        return {
          ...carry,
          [i]: {
            x: node.x,
            y: node.y,
          },
        };
      }, {});

      updateBatch(values);
    }
  }, 300);

  function drag(event: any) {
    const x = node.value.x + event.dx;
    const y = node.value.y + event.dy;

    if (x < 0 || x > boundary.width) {
      return;
    }

    if (y < 0 || y > boundary.height) {
      return;
    }

    node.value.x = x;
    node.value.y = y;

    makeSureMoveChildren(event.dx, event.dy);
    makeSureUpdateChildren();
  }

  function resize(event: any) {
    const width = node.value.width + event.dx;
    const height = node.value.height + event.dy;

    if (width < 0 || width > boundary.value.width || width < NODE_WIDTH) {
      return;
    }

    if (height < 0 || height > boundary.value.height || height < NODE_HEIGHT) {
      return;
    }

    node.value.width = width;
    node.value.height = height;
  }

  function dragStart({ sourceEvent }: any) {
    element.value.classed("select-none cursor-move", true);
    element.value.classed("cursor-pointer", false);

    changed.value = false;
    resizable.value = Array.from(sourceEvent.target.classList).includes(
      "resize-point"
    );
  }

  function dragging(event: any) {
    changed.value = true;
    if (resizable.value) {
      resize(event);
    } else {
      drag(event);
    }
  }

  function dragEnd() {
    resizable.value = false;

    element.value.classed("select-none cursor-move", false);
    element.value.classed("cursor-pointer", true);

    if (changed.value) {
      update({
        x: node.value.x,
        y: node.value.y,
        width: node.value.width,
        height: node.value.height,
      });

      makeSureEnterGroup();
      makeSureLeaveGroup();
    }
  }

  async function makeSureEnterGroupWithoutConnections(node: any) {
    const sourceLink = links.value.find(
      (i: any) => i.source_id === node.value._id
    );

    const destinationLink = links.value.find(
      (i: any) => i.destination_id === node.value._id
    );

    if (sourceLink) {
      link.removeById(sourceLink._id);
    }

    if (destinationLink) {
      link.removeById(destinationLink._id);
    }
  }

  function makeSureEnterGroup() {
    const groups = nodes.value.filter((group: any) => {
      if (group.type === NodeType.Group) {
        return into(group, node.value);
      }

      return false;
    });

    let group = groups.reduce((carry: any, item: any) => {
      if (!carry) {
        return item;
      }

      return carry.x < item.x ? item : carry;
    }, null);

    if (!group && groups.length > 0) {
      group = groups[0];
    }

    if (group) {
      const children = group.children ?? [];
      if (!children.includes(node.value._id)) {
        makeSureEnterGroupWithoutConnections(node);
        updateById(group._id, {
          children: [...children, node.value._id],
        });
      }
    }
  }

  function makeSureLeaveGroup() {
    const group = nodes.value.find((group: any) => {
      return (group.children ?? []).includes(node.value._id);
    });

    if (group) {
      if (!into(group, node.value)) {
        updateById(group._id, {
          children: group.children.filter((i: string) => i !== node.value._id),
        });
      }
    }
  }

  function makeSureMoveChildren(dx: number, dy: number) {
    if (node.value.children && node.value.children.length) {
      nodes.value = nodes.value.map((i: any) => {
        if (node.value.children.includes(i._id)) {
          return {
            ...i,
            x: i.x + dx,
            y: i.y + dy,
          };
        }

        return i;
      });
    }
  }

  onMounted(() => {
    element.value = d3.select(`#${nodeId.value}`);

    if (!disabled.value) {
      element.value.call(
        d3.drag().on("start", dragStart).on("drag", dragging).on("end", dragEnd)
      );
    }
  });

  watch(node, (newValue: any, oldValue: any) => {
    if (newValue.x !== oldValue.x || newValue.y !== oldValue.y) {
      makeSureMoveChildren(newValue.x - oldValue.x, newValue.y - oldValue.y);
      makeSureUpdateChildren();
    }
  });

  return {
    nodeId,
    disabled,
  };
}

export function useDisconnect(node: any) {
  const links = inject<any>("links");

  const sourceLink = computed(() => {
    return links.value.find((i: any) => i.source_id === node.value._id);
  });

  const destinationLink = computed(() => {
    return links.value.find((i: any) => i.destination_id === node.value._id);
  });

  const isConnected = computed(() => {
    return sourceLink.value || destinationLink.value;
  });

  return {
    sourceLink,
    isConnected,
    destinationLink,
  };
}
