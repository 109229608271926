<template>
  <Button
    :disabled="mapping"
    :loading="mapping"
    v-tooltip="'Auto Map'"
    @click="handleMap"
  >
    <LinkIcon class="w-5 h-5" />
  </Button>
</template>

<script>
import { defineComponent, ref, inject } from "vue";
import { LinkIcon } from "@heroicons/vue/outline";
import Button from "@/components/common/Button";

export default defineComponent({
  components: {
    Button,
    LinkIcon,
  },

  props: {
    sourceFields: Array,
    destinationFields: Array,
  },

  setup(props, context) {
    const mapping = ref(false);
    const $axios = inject("axios");

    const handleMap = async () => {
      mapping.value = true;

      try {
        try {
          const { data: mappings } = await $axios.post("mappings", {
            source: props.sourceFields.map((i) => i.name),
            destination: props.destinationFields.map((i) => i.name),
          });

          context.emit("map", mappings.data);
        } catch (error) {
          //
        }
      } catch (error) {
        //
      }

      mapping.value = false;
    };

    return {
      mapping,
      handleMap,
    };
  },
});
</script>
