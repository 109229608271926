import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-234e8004"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "chart",
  class: "relative mt-3 border border-gray-50 rounded-sm"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Toolbox = _resolveComponent("Toolbox")!
  const _component_Link = _resolveComponent("Link")!
  const _component_PendingLink = _resolveComponent("PendingLink")!
  const _component_Node = _resolveComponent("Node")!
  const _component_Group = _resolveComponent("Group")!
  const _component_LinkMarker = _resolveComponent("LinkMarker")!
  const _component_perfect_scrollbar = _resolveComponent("perfect-scrollbar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Toolbox),
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(), _createElementBlock("svg", {
        class: "absolute left-0 top-0",
        style: _normalizeStyle(_ctx.boundaryStyle),
        "xml:space": "preserve"
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chartLinks, (link) => {
          return (_openBlock(), _createBlock(_component_Link, {
            link: link,
            key: link._id
          }, null, 8, ["link"]))
        }), 128)),
        (_ctx.pendingLink)
          ? (_openBlock(), _createBlock(_component_PendingLink, {
              key: 0,
              node: _ctx.pendingLink,
              position: _ctx.pendingPosition,
              onClose: _ctx.handlePendingClose
            }, null, 8, ["node", "position", "onClose"]))
          : _createCommentVNode("", true)
      ], 4)),
      _createVNode(_component_perfect_scrollbar, {
        class: "absolute left-0 top-0",
        style: _normalizeStyle(_ctx.boundaryStyle)
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chartNodes, (node) => {
            return (_openBlock(), _createBlock(_component_Node, {
              key: node._id,
              node: node,
              boundary: _ctx.boundary,
              onLink: ($event: any) => (_ctx.handleLink(node, $event))
            }, null, 8, ["node", "boundary", "onLink"]))
          }), 128)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chartGroups, (group) => {
            return (_openBlock(), _createBlock(_component_Group, {
              key: group._id,
              group: group,
              boundary: _ctx.boundary,
              onLink: ($event: any) => (_ctx.handleLink(group, $event))
            }, null, 8, ["group", "boundary", "onLink"]))
          }), 128)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.configureLinks, (link) => {
            return (_openBlock(), _createBlock(_component_LinkMarker, {
              link: link,
              key: link._id,
              onClick: ($event: any) => (_ctx.handleLinkClick(link))
            }, null, 8, ["link", "onClick"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["style"])
    ])
  ], 64))
}